
import { Options, Vue } from "vue-class-component";
import { mapGetters } from "vuex";
import SchematrixApi from "@/services/schematrix";
import ManifestDTO from "@/models/manifest-dto";
import FolderTreeFolderNode from "@/components/FolderTreeFolderNode.vue";
import ManifestFolderDTO from "@/models/manifest-folder-dto";
import FileDropInfo from "@/models/file-drop-info";

@Options({
  data() {
    return {
      manifest: ManifestDTO,
    };
  },
  computed: {
    ...mapGetters(["login"]),
    folders() {
      return this.manifest.Folders;
    },
  },
  props: ["containerID", "containerName"],
  emits: ["select", "expand", "loaded", "files-dropped"],
  watch: {
    containerID: function (newVal) {
      if (!newVal || newVal === "null") {
        this.manifest = undefined;
        return;
      }
      this.refresh();
      /*
      SchematrixApi.getContainerManifest(this.login.Token, newVal, true)
        .then((manifest) => {
          manifest.Name = "[ Top ]";
          this.assignManifestPaths("/", manifest);
          this.manifest = manifest;
          this.folderNodeSelect(this.manifest);
          this.$emit("loaded");
        })
        .catch(() => {
          this.manifest = undefined;
        });*/
    },
  },
  methods: {
    refresh(selectedPath = "/"): Promise<void> {
      return SchematrixApi.getContainerManifest(this.login.Token, this.containerID, true)
        .then((manifest) => {
          manifest.Name = "[ Top ]";
          this.assignManifestPaths("/", manifest);
          this.manifest = manifest;
          if (selectedPath === "/") {
            this.folderNodeSelect(this.manifest);
          } else {
            this.folderPathSelect(selectedPath);
          }
          this.$emit("loaded");
        })
        .catch(() => {
          this.manifest = undefined;
        });
    },
    assignManifestPaths(folderPath: string, folder: ManifestDTO | ManifestFolderDTO) {
      folder.Path = folderPath;
      if (folder.Folders) {
        folder.Folders.forEach((subfolder) => {
          this.assignManifestPaths(folderPath + subfolder.Name + "/", subfolder);
        });
      }
      if (folder.Files) {
        folder.Files.forEach((file) => {
          file.Path = folderPath + "/" + file.Name;
        });
      }
    },
    deselectAllBut(parent: ManifestFolderDTO | ManifestDTO, folder: ManifestFolderDTO) {
      if (parent === folder) {
        parent.Selected = true;
      } else {
        parent.Selected = false;
      }
      if (parent.Folders) {
        for (let i = 0; i < parent.Folders.length; i++) {
          if (parent.Folders[i] !== folder) {
            parent.Folders[i].Selected = false;
          }
          this.deselectAllBut(parent.Folders[i], folder);
        }
      }
    },
    findPathItem(parent: ManifestDTO | ManifestFolderDTO, path: string): ManifestDTO | ManifestFolderDTO | undefined {
      if (path === "/") {
        return this.manifest;
      } else if (parent.Folders) {
        for (let i = 0; i < parent.Folders.length; i++) {
          if (parent.Folders[i].Path === path) {
            return parent.Folders[i];
          }
          const result = this.findPathItem(parent.Folders[i], path);
          if (result) {
            return result;
          }
        }
        return undefined;
      }
    },
    folderNodeSelect: function (folder: ManifestFolderDTO | ManifestDTO) {
      this.deselectAllBut(this.manifest, folder);
      let path = folder.Path;
      folder.Selected = true;
      this.$emit("select", path);
    },
    folderPathSelect: function (path: string) {
      const pathItem = this.findPathItem(this.manifest, path);
      if (pathItem) {
        this.folderNodeSelect(pathItem);
        this.expandItem(this.manifest, path);
      }
    },
    expandItem(folder: ManifestDTO | ManifestFolderDTO, path: string) {
      if (path !== "/") {
        this.manifest.Expanded = true;
      }
      if (folder.Folders) {
        for (let i = 0; i < folder.Folders.length; i++) {
          const subfolder = folder.Folders[i];
          const subFolderPath = subfolder.Path;
          if (subFolderPath && path.startsWith(subFolderPath)) {
            subfolder.Expanded = true;
            this.expandItem(subfolder, path);
          }
        }
      }
    },
    folderNodeExpand: function (folder: ManifestFolderDTO | ManifestDTO) {
      folder.Expanded = !folder.Expanded;
      this.$emit("expand", folder);
    },
    onFilesDropped(event: FileDropInfo) {
      this.$emit("files-dropped", event);
    },
  },
  components: {
    FolderTreeFolderNode,
  },
})
export default class FolderTree extends Vue {}
