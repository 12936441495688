
import { Options, Vue } from "vue-class-component";
import SchematrixApi from "@/services/schematrix";
import { createToast, ToastType } from "mosha-vue-toastify";
import LoginPanel from "./components/LoginPanel.vue";
import ChangePasswordModal from "@/components/ChangePasswordModal.vue";
import EditUserModal from "@/components/EditUserModal.vue";
import { store } from "./store";
import { mapGetters } from "vuex";
import { logger } from "@/services/utilities";
import config from "@/config/config.json";
import ModalResult from "./models/modal-result";
import ChangePasswordRequest from "@/models/change-password-request";
import LoginDTO from "@/models/login-dto";

@Options({
  data() {
    return {
      loginChecked: false,
      timerHandle: undefined,
      userDropDownActive: false,
      changePasswordModalActive: false,
      changingPassword: false,
      editUserModalActive: false,
      updatingUser: false,
    };
  },
  computed: {
    ...mapGetters(["isLoggedIn", "login"]),
    currentEmail() {
      return this.login.Email;
    },
    currentAlias() {
      return this.login.Alias;
    },
  },
  components: {
    LoginPanel,
    ChangePasswordModal,
    EditUserModal,
  },
  methods: {
    showToast(title: string, message: string, toastType: ToastType, timeout = 5000) {
      createToast(
        {
          title: title,
          description: message,
        },
        {
          type: toastType,
          timeout: timeout,
          position: "top-right",
          hideProgressBar: true,
          showIcon: true,
        }
      );
    },
    onError(error: never) {
      logger(error);
      this.showToast("Error", error, "danger");
    },
    logOut() {
      this.userDropDownActive = false;
      store.dispatch("logOut");
    },
    showChangePassword() {
      this.userDropDownActive = false;
      this.changePasswordModalActive = true;
    },
    changePasswordModalClosed(result: ModalResult<ChangePasswordRequest>) {
      if (result.cancelled) {
        this.changePasswordModalActive = false;
        return;
      } else {
        this.changingPassword = true;
        let request = result.data;
        request.Name = this.login.Name;
        SchematrixApi.changePassword(this.login.Token, request)
          .then(() => {
            logger(`Password changed for ${request.Name} created successfully`);
            this.showToast("Password Changed Successfully", undefined, "success", 3000);
          })
          .catch((error) => {
            this.onError(error);
          })
          .finally(() => {
            this.changingPassword = false;
            this.changePasswordModalActive = false;
          });
      }
    },
    showEditUser() {
      this.userDropDownActive = false;
      this.editUserModalActive = true;
    },
    editUserModalClosed(result: ModalResult<LoginDTO>) {
      if (result.cancelled) {
        this.editUserModalActive = false;
        return;
      } else {
        this.updatingUser = true;
        let request = result.data;
        request.Name = this.login.Name;
        SchematrixApi.updateUser(this.login.Token, request)
          .then(() => {
            logger(`User ${request.Name} updated successfully`);
            this.showToast("Account Saved", undefined, "success", 3000);
          })
          .catch((error) => {
            this.onError(error);
          })
          .finally(() => {
            this.updatingUser = false;
            this.editUserModalActive = false;
          });
      }
    },
    checkLoginStatus() {
      // logger("Checking login status: " + new Date().toLocaleString());
      var tokenDate = new Date(this.login.TokenExpiration);
      var nowDate = new Date();
      var tokenMilliSeconds = tokenDate.getTime() - nowDate.getTime();
      // logger(`Remaining token time: ${msToTime(tokenMilliSeconds)}`);
      // logger(`Remaining token ms: ${tokenMilliSeconds}`);
      if (tokenMilliSeconds < config.refreshTokenThreshold) {
        store.dispatch("refreshToken");
      }
    },
  },
  watch: {
    isLoggedIn: function (newVal) {
      logger("Login state changed to " + newVal);
      if (!newVal) {
        if (this.timerHandle !== undefined) {
          logger("Stopping check login timer");
          clearInterval(this.timerHandle);
          this.timerHandle = undefined;
        }
      } else {
        if (this.timerHandle === undefined && config.refreshTokens) {
          logger("Starting check login timer");
          this.timerHandle = setInterval(this.checkLoginStatus, config.tokenCheckPeriod);
        }
      }
    },
  },
  mounted() {
    store.dispatch("checkLogIn").then(() => {
      this.loginChecked = true;
    });
  },
})
export default class App extends Vue {}
