import { InjectionKey } from "vue";
import { ActionContext, createStore, Store, useStore as baseUseStore } from "vuex";
import router from "@/router";
import config from "@/config/config.json";
import SchematrixApi from "@/services/schematrix";

import ContainerDTO from "@/models/container-dto";
import LoginDTO from "@/models/login-dto";
import { logger } from "@/services/utilities";

interface IState {
  serviceUrl: string;

  isLoggedIn: boolean;
  login?: LoginDTO;

  containersLoaded: boolean;
  containers: ContainerDTO[];
}

// define injection key
export const key: InjectionKey<Store<IState>> = Symbol();

export const store: Store<IState> = createStore<IState>({
  state: {
    serviceUrl: config.schematrixServiceUrl,
    isLoggedIn: false,
    login: undefined,
    containersLoaded: false,
    containers: [],
  },
  mutations: {
    SET_IS_LOGGED_IN(state: IState, value: boolean): void {
      state.isLoggedIn = value;
      if (!value) {
        state.containersLoaded = false;
        state.containers = [];
      }
    },
    SET_LOGIN(state: IState, value?: LoginDTO): void {
      if (!value || value.LoginId != state.login?.LoginId) {
        state.containersLoaded = false;
        state.containers = [];
      }
      state.login = value;
    },
    SET_CONTAINERS_LOADED(state: IState, value: boolean): void {
      state.containersLoaded = value;
    },
    SET_CONTAINERS(state: IState, value: ContainerDTO[]): void {
      state.containers = value;
    },
  },
  actions: {
    checkLogIn(context: ActionContext<IState, IState>): Promise<void> {
      return new Promise((resolve) => {
        // Determine if token is in local storage
        const existingLoginString = localStorage.getItem("login");
        let existingLogin: LoginDTO | undefined = undefined;
        if (existingLoginString) {
          existingLogin = JSON.parse(existingLoginString);
        }

        // If no existing login, then set logged in to false
        if (!existingLogin || !existingLogin.Token) {
          context.commit("SET_IS_LOGGED_IN", false);
          context.commit("SET_LOGIN", null);
          resolve();
          return;
        }

        // Validate token with API
        SchematrixApi.validateToken(existingLogin.Token)
          .then(() => {
            context.commit("SET_LOGIN", existingLogin);
            context.commit("SET_IS_LOGGED_IN", true);
            resolve();
          })
          .catch(() => {
            context.commit("SET_IS_LOGGED_IN", false);
            context.commit("SET_LOGIN", undefined);
            resolve();
          });
      });
    },
    refreshToken(context: ActionContext<IState, IState>): Promise<void> {
      return new Promise((resolve, reject) => {
        const token = context.state.login?.Token;
        if (token) {
          // logger("Refreshing token...");
          SchematrixApi.refreshToken(token)
            .then((newLogin: LoginDTO) => {
              // logger("Token refreshed.");
              context.commit("SET_LOGIN", newLogin);
              resolve();
            })
            .catch((error) => {
              logger(`Error ${error} refreshing token.`);
              localStorage.removeItem("login");
              context.commit("SET_IS_LOGGED_IN", false);
              context.commit("SET_CONTAINERS_LOADED", false);
              context.commit("SET_CONTAINERS", []);
              router.push("/");
              reject(error);
            });
        }
      });
    },
    setLogin(context: ActionContext<IState, IState>, loginDTO?: LoginDTO): void {
      if (loginDTO) {
        localStorage.setItem("login", JSON.stringify(loginDTO));
        context.commit("SET_IS_LOGGED_IN", true);
      } else {
        localStorage.removeItem("login");
        context.commit("SET_IS_LOGGED_IN", false);
      }
      context.commit("SET_LOGIN", loginDTO);
    },
    logOut(context: ActionContext<IState, IState>): void {
      localStorage.removeItem("login");
      context.commit("SET_IS_LOGGED_IN", false);
      context.commit("SET_CONTAINERS_LOADED", false);
      context.commit("SET_CONTAINERS", []);
      router.push("/");
    },
    setContainers(context: ActionContext<IState, IState>, value: ContainerDTO[]): void {
      context.commit("SET_CONTAINERS", value);
    },
    refreshContainers(context: ActionContext<IState, IState>, forceRefresh: boolean): Promise<void> {
      return new Promise((resolve) => {
        if (context.state.containersLoaded && !forceRefresh) {
          resolve();
          return;
        }
        if (!context.state.isLoggedIn || !context.state.login || !context.state.login.Token) {
          context.commit("SET_CONTAINERS_LOADED", false);
          context.commit("SET_CONTAINERS", []);
          resolve();
          return;
        }
        SchematrixApi.listContainers(context.state.login.Token)
          .then((result) => {
            context.commit("SET_CONTAINERS", result);
            context.commit("SET_CONTAINERS_LOADED", true);
          })
          .catch(() => {
            context.commit("SET_CONTAINERS_LOADED", false);
            context.commit("SET_CONTAINERS", []);
          })
          .finally(() => {
            resolve();
          });
      });
    },
  },
  getters: {
    isLoggedIn: (state) => state.isLoggedIn,
    login: (state) => state.login,
    containersLoaded: (state) => state.containersLoaded,
    containers: (state) => state.containers,
  },
  modules: {},
});

export function useStore(): Store<IState> {
  return baseUseStore(key);
}
