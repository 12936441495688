<template>
  <div v-bind:class="{ 'is-active': isActive }" class="modal" style="user-select: none">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Create Container</p>
        <button class="delete" aria-label="close" @click="onCancel"></button>
      </header>
      <section class="modal-card-body">
        <div class="notification is-info has-text-left">
          <strong>Container names can contain the following characters and be up to 256 characters in length:</strong>
          <br />
          A-Z, a-z, 0-9, exclamation point (!), underscore (_), period (.), asterisk (*), apostrophe ('), parentheses (()),
          hyphen (-) and space ( ), but cannot begin or end with a space ( ).
        </div>
        <div class="field">
          <div class="control">
            <input
              class="input"
              ref="containerName"
              type="text"
              v-bind:class="{ 'is-danger': !isValidContainerName }"
              v-on:keyup.enter="onOk"
              v-on:keyup.esc="onCancel"
              v-model="containerName"
              placeholder="New Container Name"
            />
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button
          class="button is-primary"
          @click="onOk"
          :disabled="!isValidContainerName"
          v-bind:class="{ 'is-loading': isProcessing }"
        >
          Create
        </button>
        <button class="button" @click="onCancel">Cancel</button>
      </footer>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { validContainerName } from "@/services/utilities";
import ModalResult from "@/models/modal-result";
@Options({
  data() {
    return {
      containerName: "",
    };
  },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    isProcessing: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["modal-closed"],
  computed: {
    isValidContainerName() {
      return validContainerName(this.containerName);
    },
  },
  methods: {
    onOk() {
      this.$emit("modal-closed", new ModalResult<string>(false, this.containerName));
    },
    onCancel() {
      this.$emit("modal-closed", new ModalResult<string>(true, ""));
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.containerName.focus();
    });
  },
})
export default class CreateContainerModal extends Vue {}
</script>
