<template>
  <div class="has-text-left" style="user-select: none" v-if="folderFiles.length === 0">No Files</div>
  <table class="files-list table" v-if="folderFiles.length !== 0">
    <tbody>
      <tr style="vertical-align: middle" v-for="folderFile in folderFiles" v-bind:key="folderFile.Name">
        <td class="has-text-left dont-break-out">
          <div>
            <a href="#" @click="$emit('select-file', folderFile)">{{ folderFile.Name }}</a>
            <!-- <a :href="folderFile.GetUrl" target="_blank">{{ folderFile.Name }}</a> -->
          </div>
          <div class="date" v-if="showDate">{{ folderFile.TimeStamp }}</div>
        </td>
        <td class="size has-text-right" v-if="showSize" v-html="innerFormatBytes(folderFile.Size)"></td>
        <td style="width: 72px; padding: 4px">
          <div>
            <span
              style="margin-right: 4px; color: #ff6e6e"
              class="is-small"
              title="Delete"
              @click.left.exact="$emit('delete-file', folderFile)"
              @click.shift.left.exact="$emit('immediate-delete-file', folderFile)"
            >
              <i class="fas fa-trash"></i>
            </span>
            <span
              style="margin-right: 4px; color: hsl(204, 86%, 53%)"
              class="is-small"
              title="Download"
              @click.exact="$emit('download-file', folderFile)"
            >
              <i class="fas fa-cloud-download-alt"></i>
            </span>
            <span
              style="color: hsl(204, 86%, 53%)"
              class="is-small"
              title="Copy Link"
              @click.exact="$emit('copy-link', folderFile)"
            >
              <i class="fas fa-link"></i>
            </span>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { formatBytes } from "@/services/utilities";
import ManifestFileDTO from "@/models/manifest-file-dto";

@Options({
  props: {
    folderPath: {
      type: String,
      default: "",
    },
    folderFiles: {
      type: Array,
      default: new Array<ManifestFileDTO>(),
    },
    allowDelete: {
      type: Boolean,
      default: true,
    },
    showDownload: {
      type: Boolean,
      default: true,
    },
    showSize: {
      type: Boolean,
      default: true,
    },
    showDate: {
      type: Boolean,
      default: true,
    },
    confirmFileDelete: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selectedFile: "",
    };
  },
  emits: ["select-file", "download-file", "delete-file", "immediate-delete-file", "copy-link"],
  methods: {
    innerFormatBytes(bytes: number, decimals: number) {
      return formatBytes(bytes, decimals);
    },
  },
})
export default class FileList extends Vue {}
</script>

<style lang="scss" scoped>
.files-list {
  user-select: none;

  .dont-break-out {
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }

  a,
  .name,
  .size {
    font-size: 14px;
  }

  a {
    font-size: 14px;
    color: hsl(204, 86%, 53%);
  }

  a:hover {
    text-decoration: underline;
  }

  .date {
    font-size: 10px;
    color: #a4a4a4;
  }

  tr:hover {
    background-color: rgb(191, 232, 255);
  }

  i {
    cursor: pointer;
  }
}
</style>
