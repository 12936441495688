
import { Options, Vue } from "vue-class-component";
import ModeEvent from "../models/mode-event";
import SchematrixApi from "@/services/schematrix";
import LoginDTO from "../models/login-dto";
import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";

class Fields {
  username = "";
}

class Errors {
  username?: string;
}

@Options({
  emits: ["set-mode"],
  data() {
    return {
      fields: new Fields(),
      errors: new Errors(),
      processing: false,
      serverError: undefined,
    };
  },
  computed: {
    usernameOk() {
      return this.fields.username.trim().length > 0;
    },
    codeOk() {
      return this.fields.code.trim().length > 0;
    },
  },
  methods: {
    validateForm(fields: Fields) {
      const errors = new Errors();
      if (!fields.username) errors.username = "User name or email address is required.";
      return errors;
    },
    requestPasswordReset(event: Event): void {
      event.preventDefault();
      this.errors = this.validateForm(this.fields);
      if (Object.keys(this.errors).length) return;
      let dto = new LoginDTO();
      dto.Name = this.fields.username;
      this.serverError = undefined;
      this.processing = true;
      SchematrixApi.resendRegistrationCode(dto)
        .then(() => {
          this.serverError = undefined;
          createToast(
            {
              title: "Code Resent",
              description: "Please check your email and confirm your registration with the code received.",
            },
            {
              type: "success",
              timeout: 10000,
              position: "top-right",
              hideProgressBar: true,
              showIcon: true,
            }
          );
          this.$emit("set-mode", new ModeEvent("register-confirm"));
        })
        .catch((error) => {
          this.serverError = error;
          this.$emit("set-mode", new ModeEvent("resend-registration"));
        })
        .finally(() => {
          this.processing = false;
        });
    },
    clearError(event: MouseEvent): void {
      event.preventDefault();
      this.serverError = undefined;
    },
    setModeLogin(event: MouseEvent) {
      event.preventDefault();
      this.$emit("set-mode", new ModeEvent("login"));
    },
    setModeRegisterConfirm(event: MouseEvent) {
      event.preventDefault();
      this.$emit("set-mode", new ModeEvent("register-confirm"));
    },
  },
})
export default class ResendRegistartionCode extends Vue {}
