<template>
  // eslint-disable-next-line prettier/prettier
  <div v-bind:class="{ 'is-active': isActive }" class="modal" tabindex="0" style="user-select: none" v-on:keyup.esc="onCancel">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Edit Account</p>
        <button class="delete" aria-label="close" @click="onCancel"></button>
      </header>
      <section class="modal-card-body">
        <form>
          <div class="field">
            <div class="control">
              <input class="input" type="text" autocomplete="username" v-model="username" style="display: none" />
            </div>
          </div>
          <div class="field">
            <label class="label has-text-left">Email Address</label>
            <div class="control">
              <input
                ref="email"
                class="input"
                type="email"
                autocomplete="email"
                v-model="email"
                :disabled="isProcessing"
                @input="emailInput = true"
              />
            </div>
            <div style="color: red" class="has-text-left" v-if="!emailOk && emailInput">
              {{ emailErrors }}
            </div>
          </div>
          <div class="field">
            <label class="label has-text-left">Alias (Display Name)</label>
            <div class="control">
              <input
                class="input"
                type="text"
                v-model="alias"
                autocomplete="name"
                :disabled="isProcessing"
                @input="aliasInput = true"
              />
            </div>
            <div style="color: red" class="has-text-left" v-if="!aliasOk && aliasInput">
              {{ aliasErrors }}
            </div>
          </div>
        </form>
      </section>
      <footer class="modal-card-foot">
        <button
          class="button is-danger"
          @click="onOk"
          :disabled="!formOk || isProcessing"
          v-bind:class="{ 'is-loading': isProcessing }"
        >
          Save
        </button>
        <button class="button" @click="onCancel">Cancel</button>
      </footer>
    </div>
  </div>
</template>

<script lang="ts">
import LoginDTO from "@/models/login-dto";
import ModalResult from "@/models/modal-result";
import { Options, Vue } from "vue-class-component";
@Options({
  data() {
    return {
      username: "",
      email: this.currentEmail,
      emailErrors: "",
      emailInput: false,
      alias: this.currentAlias,
      aliasErrors: "",
      aliasInput: false,
    };
  },
  props: {
    currentEmail: {
      type: String,
      default: "",
    },
    currentAlias: {
      type: String,
      default: "",
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    isProcessing: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    emailOk() {
      return this.email && this.email.trim().length > 0 && this.isEmail(this.email);
    },
    aliasOk() {
      return this.alias && this.alias.trim().length > 0;
    },
    formOk() {
      return this.validateForm();
    },
  },
  emits: ["modal-closed"],
  methods: {
    isEmail(email: string) {
      const re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
    validateForm() {
      this.emailErrors = "";
      this.aliasErrors = "";
      if (!this.email) this.emailErrors = "Email is required.";
      if (!this.isEmail(this.email)) this.emailErrors = "Email is invalid.";
      if (!this.alias) this.aliasErrors = "Alias is required.";
      return !(this.emailErrors || this.aliasErrors);
    },
    onOk() {
      let login = new LoginDTO();
      login.Email = this.email;
      login.Alias = this.alias;
      this.$emit("modal-closed", new ModalResult(false, login));
    },
    onCancel() {
      this.$emit("modal-closed", new ModalResult(true, undefined));
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.email.focus();
    });
  },
})
export default class EditUserModal extends Vue {}
</script>
