<template>
  <div v-bind:class="{ 'is-active': isActive }" class="modal" style="user-select: none">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">File Download Link</p>
        <button class="delete" aria-label="close" @click="onCancel"></button>
      </header>
      <section class="modal-card-body">
        <div class="field">
          <div class="control">
            <textarea class="textarea" rows="6" ref="link" v-on:keyup.esc="onCancel" v-model="link" readonly="readonly" />
          </div>
        </div>
        <div>
          <a :href="link" download="filename">Download</a>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button" @click="onCancel">Close</button>
      </footer>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import ModalResult from "@/models/modal-result";
@Options({
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    link: {
      type: String,
      default: "",
    },
    filename: {
      type: String,
      default: "",
    },
  },
  emits: ["modal-closed"],
  methods: {
    onCancel() {
      this.$emit("modal-closed", new ModalResult<string>(true, ""));
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.link.focus();
      this.$refs.link.select();
    });
  },
})
export default class FileLinkModal extends Vue {}
</script>
