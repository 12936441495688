<template>
  <div v-bind:class="{ 'is-active': isActive }" class="modal" style="user-select: none">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Create Folder</p>
        <button class="delete" aria-label="close" @click="onCancel"></button>
      </header>
      <section class="modal-card-body">
        <div class="notification is-info has-text-left">
          <strong>Folder names can contain the following characters:</strong>
          <br />
          A-Z, a-z, 0-9, exclamation point (!), underscore (_), period (.), asterisk (*), apostrophe ('), parentheses (()),
          hyphen (-) and space ( ). Child folders can be created by separating them with forward slash characters (e.g.
          /path/to/folder).
          <span v-if="folderPath !== '/'">
            To create a subfolder of the top folder, prefix the folder name with a forward slash (/). Otherwise the folder will
            become a subfolder of the current folder.
          </span>
        </div>
        <div class="field">
          <div class="control">
            <input
              class="input"
              ref="newFolderPath"
              type="text"
              v-model="newFolderPath"
              v-bind:class="{ 'is-danger': !isValidFolderPath }"
              placeholder="New Folder Name"
              v-on:keyup.enter="onOk"
              v-on:keyup.esc="onCancel"
            />
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button
          class="button is-primary"
          @click="onOk"
          v-bind:class="{ 'is-loading': isProcessing }"
          :disabled="!isValidFolderPath"
        >
          Create
        </button>
        <button class="button" @click="onCancel">Cancel</button>
      </footer>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { validFolderPath } from "@/services/utilities";
import ModalResult from "@/models/modal-result";
@Options({
  data() {
    return {
      newFolderPath: "",
    };
  },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    isProcessing: {
      type: Boolean,
      default: false,
    },
    folderPath: {
      type: String,
      default: "",
    },
  },
  emits: ["modal-closed"],
  computed: {
    isValidFolderPath() {
      return validFolderPath(this.newFolderPath);
    },
  },
  methods: {
    onOk() {
      this.$emit("modal-closed", new ModalResult<string>(false, this.newFolderPath));
    },
    onCancel() {
      this.$emit("modal-closed", new ModalResult<string>(true, ""));
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.newFolderPath.focus();
    });
  },
})
export default class CreateFolderModal extends Vue {}
</script>
