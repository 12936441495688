<template>
  <div class="notification is-danger" v-if="serverError">
    <button class="delete" @click="clearError"></button>
    {{ serverError }}
  </div>
  <nav class="panel is-info">
    <p class="panel-heading">Log In</p>
    <div class="panel-block">
      <form style="width: 100%" @submit="login">
        <div class="field">
          <label class="label has-text-left">Login Name or Email Address</label>
          <div class="control has-icons-left has-icons-right">
            <input class="input" type="text" autocomplete="username" v-model="fields.username" :disabled="processing" />
            <span class="icon is-small is-left">
              <i class="fas fa-user"></i>
            </span>
          </div>
          <div style="color: red" class="has-text-left" v-if="!usernameOk">{{ errors.username }}</div>
        </div>
        <div class="field">
          <label class="label has-text-left">Password</label>
          <div class="control has-icons-left has-icons-right">
            <input
              class="input"
              type="password"
              v-model="fields.password"
              autocomplete="current-password"
              :disabled="processing"
            />
            <span class="icon is-small is-left">
              <i class="fas fa-keyboard"></i>
            </span>
          </div>
          <div style="color: red" class="has-text-left" v-if="!passwordOk">{{ errors.password }}</div>
        </div>
        <div>
          <button class="button mt-3 mx-1 is-info" @click="logIn" v-bind:class="{ 'is-loading': processing }">Log In</button>
        </div>
      </form>
    </div>
  </nav>
  <a @click="setModeForgotPassword">I forgot my password</a>
  <br />
  <a @click="setModeRegister">Register a new account</a>
  <br />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { store } from "../store";
import SchematrixApi from "../services/schematrix";
import LoginRequest from "../models/login-request";
import ModeEvent from "../models/mode-event";

class Fields {
  username = "";
  password = "";
}

class Errors {
  username?: string;
  password?: string;
}

@Options({
  emits: ["set-mode"],
  data() {
    return {
      fields: new Fields(),
      errors: new Errors(),
      serverError: undefined,
      processing: false,
    };
  },
  computed: {
    usernameOk() {
      return this.fields.username.trim().length > 0;
    },
    passwordOk() {
      return this.fields.password.trim().length > 0;
    },
  },
  methods: {
    validateForm(fields: Fields) {
      const errors = new Errors();
      if (!fields.username) errors.username = "User name or email is required.";
      if (!fields.password) errors.password = "Password is required.";
      return errors;
    },
    logIn(event: Event): void {
      event.preventDefault();
      this.errors = this.validateForm(this.fields);
      if (Object.keys(this.errors).length) return;
      this.serverError = undefined;
      this.processing = true;
      SchematrixApi.login(new LoginRequest(this.fields.username, this.fields.password))
        .then((loginDTO) => {
          this.serverError = undefined;
          store.dispatch("setLogin", loginDTO);
        })
        .catch((error) => {
          this.serverError = error;
          store.dispatch("setLogin", undefined);
        })
        .finally(() => {
          this.processing = false;
        });
    },
    clearError(event: MouseEvent): void {
      event.preventDefault();
      this.serverError = undefined;
    },
    setModeForgotPassword(event: MouseEvent) {
      event.preventDefault();
      this.$emit("set-mode", new ModeEvent("forgot-password"));
    },
    setModeRegister(event: MouseEvent) {
      event.preventDefault();
      this.$emit("set-mode", new ModeEvent("register"));
    },
  },
})
export default class Login extends Vue {}
</script>
