export function formatBytes(bytes: number, decimals: number): string {
  if (bytes === 0) {
    return "0 Bytes";
  }
  const k = 1024;
  const dm = decimals <= 0 ? 0 : decimals || 2;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + "&nbsp;" + sizes[i];
}

export function logger(...data: unknown[]): void {
  console.log(data.join(" "));
}

export function msToTime(s: number): string {
  // Pad to 2 or 3 digits, default is 2
  // tslint:disable-next-line:typedef
  function pad(n: number, z = 2): string {
    z = z || 2;
    return ("00" + n).slice(-z);
  }

  const ms = s % 1000;
  s = (s - ms) / 1000;
  const secs = s % 60;
  s = (s - secs) / 60;
  const mins = s % 60;
  const hrs = (s - mins) / 60;

  return pad(hrs) + ":" + pad(mins) + ":" + pad(secs) + "." + pad(ms, 3);
}

export function validContainerName(name: string): boolean {
  if (!name.trim()) {
    return false;
  }
  if (name.charAt(0) === " " || name.charAt(name.length - 1) === " ") {
    return false;
  }
  const r = new RegExp("[^A-Za-z0-9 !_.*'()-]");
  if (r.test(name)) {
    return false;
  }
  return true;
}

export function validFolderPath(name: string): boolean {
  if (!name.trim()) {
    return false;
  }
  if (name.charAt(0) === " " || name.charAt(name.length - 1) === " ") {
    return false;
  }
  if (name.indexOf("//") != -1) {
    return false;
  }
  const r = new RegExp("[^A-Za-z0-9 /!_.*'()-]");
  if (r.test(name)) {
    return false;
  }
  return true;
}

export function getParentPath(path: string): string {
  if (path === "/") {
    return "";
  }
  const parts = path.split("/");
  parts.pop();
  parts.pop();
  return parts.join("/") + "/";
}
