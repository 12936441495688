
import { Options, Vue } from "vue-class-component";
import SchematrixApi from "@/services/schematrix";
import ModeEvent from "../models/mode-event";
import LoginDTO from "../models/login-dto";
import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";

class Fields {
  username = "";
  code = "";
}

class Errors {
  username?: string;
  code?: string;
}

@Options({
  emits: ["set-mode"],
  data() {
    return {
      fields: new Fields(),
      errors: new Errors(),
      serverError: undefined,
      processing: false,
    };
  },
  computed: {
    usernameOk() {
      return this.fields.username.trim().length > 0;
    },
    codeOk() {
      return this.fields.code.trim().length > 0;
    },
  },
  methods: {
    validateForm(fields: Fields) {
      const errors = new Errors();
      if (!fields.username) errors.username = "User name is required.";
      if (!fields.code) errors.code = "Confirmation code is required.";
      return errors;
    },
    confirmRegistration(event: Event): void {
      event.preventDefault();
      this.errors = this.validateForm(this.fields);
      if (Object.keys(this.errors).length) return;
      let dto = new LoginDTO();
      dto.Name = this.fields.username;
      dto.Code = this.fields.code;
      this.serverError = undefined;
      this.processing = true;
      SchematrixApi.confirmRegistration(dto)
        .then(() => {
          this.serverError = undefined;
          createToast(
            {
              title: "Account Confirmed",
              description: "Please log in.",
            },
            {
              type: "success",
              timeout: 5000,
              position: "top-right",
              hideProgressBar: true,
              showIcon: true,
            }
          );
          this.$emit("set-mode", new ModeEvent("login"));
        })
        .catch((error) => {
          this.serverError = error;
          this.$emit("set-mode", new ModeEvent("register-confirm"));
        })
        .finally(() => {
          this.processing = false;
        });
    },
    clearError(event: MouseEvent): void {
      event.preventDefault();
      this.serverError = undefined;
    },
    setModeLogin(event: MouseEvent) {
      event.preventDefault();
      this.$emit("set-mode", new ModeEvent("login"));
    },
    setModeRegister(event: MouseEvent) {
      event.preventDefault();
      this.$emit("set-mode", new ModeEvent("register"));
    },
    setModeResendCode(event: MouseEvent) {
      event.preventDefault();
      this.$emit("set-mode", new ModeEvent("resend-registration"));
    },
  },
})
export default class RegisterConfirm extends Vue {}
