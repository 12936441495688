
import { Options, Vue } from "vue-class-component";
import ModeEvent from "../models/mode-event";
import LoginDTO from "../models/login-dto";
import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";
import SchematrixApi from "@/services/schematrix";

class Fields {
  username = "";
  email = "";
  alias = "";
  password = "";
  passwordConfirm = "";
}

class Errors {
  username?: string;
  email?: string;
  alias?: string;
  password?: string;
  passwordConfirm?: string;
}

@Options({
  emits: ["set-mode"],
  data() {
    return {
      fields: new Fields(),
      errors: new Errors(),
      serverError: undefined,
      processing: false,
      usernameIsDuplicate: false,
      usernameValidated: false,
      emailValidated: false,
      emailIsDuplicate: false,
    };
  },
  computed: {
    usernameOk() {
      return this.fields.username.trim().length > 0;
    },
    emailOk() {
      return this.fields.email.trim().length > 0;
    },
    aliasOk() {
      return this.fields.alias.trim().length > 0;
    },
    passwordOk() {
      return this.fields.password.trim().length > 0;
    },
    passwordConfirmOk() {
      return this.fields.passwordConfirm.trim().length > 0 && this.fields.password === this.fields.passwordConfirm;
    },
  },
  methods: {
    isEmail(email: string) {
      const re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
    validateForm(fields: Fields) {
      const errors = new Errors();
      if (!fields.username) errors.username = "User name is required.";
      if (!fields.email) errors.email = "Email is required.";
      if (!this.isEmail(fields.email)) errors.email = "Email is invalid.";
      if (!fields.alias) errors.alias = "Alias is required.";
      if (!fields.password) errors.password = "Password is required.";
      if (!fields.passwordConfirm) errors.passwordConfirm = "Confirm password is required.";
      if (fields.password !== fields.passwordConfirm) errors.passwordConfirm = "Passwords don't match.";
      return errors;
    },
    register(event: Event): void {
      event.preventDefault();
      this.errors = this.validateForm(this.fields);
      if (Object.keys(this.errors).length) return;
      let dto = new LoginDTO();
      dto.Name = this.fields.username;
      dto.Password = this.fields.password;
      dto.Email = this.fields.email;
      dto.Alias = this.fields.alias;
      this.serverError = undefined;
      this.processing = true;
      SchematrixApi.register(dto)
        .then(() => {
          this.serverError = undefined;
          createToast(
            {
              title: "Registration Accepted",
              description:
                "Please check your email and enter the registration code emailed to you to confirm your registration.",
            },
            {
              type: "success",
              timeout: 10000,
              position: "top-right",
              hideProgressBar: true,
              showIcon: true,
            }
          );
          this.$emit("set-mode", new ModeEvent("register-confirm"));
        })
        .catch((error) => {
          this.serverError = error;
          this.$emit("set-mode", new ModeEvent("register"));
        })
        .finally(() => {
          this.processing = false;
        });
    },
    checkNameUsage(): void {
      if (this.fields.username.trim().length === 0) {
        this.usernameIsDuplicate = false;
        this.usernameValidated = false;
        return;
      }
      SchematrixApi.checkNameUsage(this.fields.username)
        .then((value) => {
          this.usernameIsDuplicate = value;
          this.usernameValidated = !value;
        })
        .catch(() => {
          this.usernameIsDuplicate = false;
          this.usernameValidated = false;
        });
    },
    checkEmailUsage(): void {
      if (this.fields.email.trim().length == 0 || !this.isEmail(this.fields.email)) {
        this.emailIsDuplicate = false;
        this.emailValidated = false;
        return;
      }
      SchematrixApi.checkEmailUsage(this.fields.email)
        .then((value) => {
          this.emailIsDuplicate = value;
          this.emailValidated = !value;
        })
        .catch(() => {
          this.emailIsDuplicate = false;
          this.emailValidated = false;
        });
    },
    clearError(event: MouseEvent): void {
      event.preventDefault();
      this.serverError = undefined;
    },
    setModeLogin(event: MouseEvent) {
      event.preventDefault();
      this.$emit("set-mode", new ModeEvent("login"));
    },
    setModeRegisterConfirm(event: MouseEvent) {
      event.preventDefault();
      this.$emit("set-mode", new ModeEvent("register-confirm"));
    },
  },
})
export default class Register extends Vue {}
