
import { Options, Vue } from "vue-class-component";
import { validContainerName } from "@/services/utilities";
import ModalResult from "@/models/modal-result";
@Options({
  data() {
    return {
      containerName: "",
    };
  },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    isProcessing: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["modal-closed"],
  computed: {
    isValidContainerName() {
      return validContainerName(this.containerName);
    },
  },
  methods: {
    onOk() {
      this.$emit("modal-closed", new ModalResult<string>(false, this.containerName));
    },
    onCancel() {
      this.$emit("modal-closed", new ModalResult<string>(true, ""));
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.containerName.focus();
    });
  },
})
export default class CreateContainerModal extends Vue {}
