
import { Options, Vue } from "vue-class-component";
import { formatBytes } from "@/services/utilities";
import ManifestFileDTO from "@/models/manifest-file-dto";

@Options({
  props: {
    folderPath: {
      type: String,
      default: "",
    },
    folderFiles: {
      type: Array,
      default: new Array<ManifestFileDTO>(),
    },
    allowDelete: {
      type: Boolean,
      default: true,
    },
    showDownload: {
      type: Boolean,
      default: true,
    },
    showSize: {
      type: Boolean,
      default: true,
    },
    showDate: {
      type: Boolean,
      default: true,
    },
    confirmFileDelete: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selectedFile: "",
    };
  },
  emits: ["select-file", "download-file", "delete-file", "immediate-delete-file", "copy-link"],
  methods: {
    innerFormatBytes(bytes: number, decimals: number) {
      return formatBytes(bytes, decimals);
    },
  },
})
export default class FileList extends Vue {}
