<template>
  <div>
    <div
      class="folder-tree-node-parent"
      v-bind:class="{ 'file-over': fileOver }"
      @dragover="onDragOver"
      @dragleave="onDragLeave"
      @drop="onDrop"
    >
      <span v-if="expandable" class="icon" @click="expand">
        <i v-if="!isExpanded" class="fas fa-chevron-right"></i>
        <i v-if="isExpanded" class="fas fa-chevron-down"></i>
      </span>
      <span v-if="!expandable" class="icon" @click="select">
        <i class="fas fa-folder"></i>
      </span>
      <span @click="select" class="folder-tree-node" v-bind:class="{ 'folder-tree-node-selected': isSelected }">
        {{ nodeName }}
      </span>
    </div>
    <div style="display: block; margin-left: 16px" v-show="isExpanded && subfolders">
      <FolderTreeFolderNode
        v-bind:key="subfolder.Name"
        v-for="subfolder in subfolders"
        :folder="subfolder"
        v-on:select="folderNodeSelect"
        v-on:expand="folderNodeExpand"
        v-on:files-dropped="onFilesDropped"
      />
    </div>
  </div>
</template>

<script lang="ts">
import ManifestDTO from "@/models/manifest-dto";
import ManifestFolderDTO from "@/models/manifest-folder-dto";
import FileDropInfo from "@/models/file-drop-info";
import { Options, Vue } from "vue-class-component";

@Options({
  props: ["folder"],
  emits: ["select", "expand", "files-dropped"],
  data() {
    return {
      fileOver: false,
    };
  },
  computed: {
    nodeName() {
      if (!this.folder) return "";
      if (this.folder.Name) {
        return this.folder.Name;
      }
    },
    subfolders() {
      if (!this.folder) return [];
      return this.folder.Folders;
    },
    expandable() {
      if (!this.folder) {
        return false;
      }
      if (this.folder.Folders && this.folder.Folders.length > 0) {
        return true;
      }
      if (this.folder.Files && this.folder.Files.length > 0) {
        return true;
      }
      return false;
    },
    isSelected() {
      if (!this.folder) return false;
      return this.folder.Selected;
    },
    isExpanded() {
      if (!this.folder) return false;
      return this.folder.Expanded;
    },
  },
  methods: {
    expand() {
      this.$emit("expand", this.folder);
    },
    select() {
      this.$emit("select", this.folder);
    },
    folderNodeSelect: function (folder: ManifestFolderDTO | ManifestDTO) {
      this.$emit("select", folder);
    },
    folderNodeExpand: function (folder: ManifestFolderDTO | ManifestDTO) {
      this.$emit("expand", folder);
    },
    onDragOver(event: DragEvent) {
      event.preventDefault();
      event.stopPropagation();
      this.fileOver = true;
    },
    onDragLeave(event: DragEvent) {
      event.preventDefault();
      event.stopPropagation();
      this.fileOver = false;
    },
    onDrop(event: DragEvent) {
      event.preventDefault();
      event.stopPropagation();
      this.fileOver = false;
      if (event.dataTransfer === null) {
        return;
      }
      if (event.dataTransfer.files === null) {
        return;
      }
      const files = event.dataTransfer.files;
      if (files.length > 0) {
        const fileDropInfo = new FileDropInfo(this.folder.Path, files);
        this.$emit("files-dropped", fileDropInfo);
      }
    },
    onFilesDropped(event: FileDropInfo) {
      this.$emit("files-dropped", event);
    },
  },
})
export default class FolderTreeFolderNode extends Vue {}
</script>
