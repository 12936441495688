
import LoginDTO from "@/models/login-dto";
import ModalResult from "@/models/modal-result";
import { Options, Vue } from "vue-class-component";
@Options({
  data() {
    return {
      username: "",
      email: this.currentEmail,
      emailErrors: "",
      emailInput: false,
      alias: this.currentAlias,
      aliasErrors: "",
      aliasInput: false,
    };
  },
  props: {
    currentEmail: {
      type: String,
      default: "",
    },
    currentAlias: {
      type: String,
      default: "",
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    isProcessing: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    emailOk() {
      return this.email && this.email.trim().length > 0 && this.isEmail(this.email);
    },
    aliasOk() {
      return this.alias && this.alias.trim().length > 0;
    },
    formOk() {
      return this.validateForm();
    },
  },
  emits: ["modal-closed"],
  methods: {
    isEmail(email: string) {
      const re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
    validateForm() {
      this.emailErrors = "";
      this.aliasErrors = "";
      if (!this.email) this.emailErrors = "Email is required.";
      if (!this.isEmail(this.email)) this.emailErrors = "Email is invalid.";
      if (!this.alias) this.aliasErrors = "Alias is required.";
      return !(this.emailErrors || this.aliasErrors);
    },
    onOk() {
      let login = new LoginDTO();
      login.Email = this.email;
      login.Alias = this.alias;
      this.$emit("modal-closed", new ModalResult(false, login));
    },
    onCancel() {
      this.$emit("modal-closed", new ModalResult(true, undefined));
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.email.focus();
    });
  },
})
export default class EditUserModal extends Vue {}
