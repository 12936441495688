<template>
  <div class="mx-2 my-2">
    <transition name="fade" mode="out-in">
      <!-- Login form -->
      <div v-if="mode === 'login'">
        <Login v-on:set-mode="setMode" />
      </div>

      <!-- Registration form -->
      <div v-else-if="mode === 'register'">
        <Register v-on:set-mode="setMode" />
      </div>

      <!-- Registration confirmation form -->
      <div v-else-if="mode === 'register-confirm'">
        <RegisterConfirm v-on:set-mode="setMode" />
      </div>

      <!-- Request password reset form -->
      <div v-else-if="mode === 'forgot-password'">
        <ForgotPassword v-on:set-mode="setMode" />
      </div>

      <!-- Reset password with code form -->
      <div v-else-if="mode === 'reset-password'">
        <ResetPassword v-on:set-mode="setMode" />
      </div>

      <!-- Resend registration code -->
      <div v-else-if="mode === 'resend-registration'">
        <ResendRegistration v-on:set-mode="setMode" />
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { mapGetters } from "vuex";
import Login from "./Login.vue";
import Register from "./Register.vue";
import RegisterConfirm from "./RegisterConfirm.vue";
import ForgotPassword from "./ForgotPassword.vue";
import ResetPassword from "./ResetPassword.vue";
import ResendRegistration from "./ResendRegistrationCode.vue";
import ModeEvent from "../models/mode-event";

@Options({
  data() {
    return {
      mode: "login",
    };
  },
  computed: {
    ...mapGetters([]),
  },
  methods: {
    setMode(modeEvent: ModeEvent) {
      this.mode = modeEvent.mode;
    },
  },
  components: { Login, Register, RegisterConfirm, ForgotPassword, ResetPassword, ResendRegistration },
})
export default class LoginPanel extends Vue {}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
