<template>
  <div class="content" v-if="isLoggedIn">
    <img src="@/assets/banner-logo.jpg" class="is-pulled-left" style="margin: 10px; height: 48px" />
    <div id="nav">
      <!--
      <router-link to="/">File Manager</router-link>
      |
      <router-link to="/about">About</router-link>
      |
      <a @click="logOut">Logout</a>
      -->
      <div class="dropdown is-pulled-right is-right" v-bind:class="{ 'is-active': userDropDownActive }">
        <div class="dropdown-trigger">
          <button
            class="button is-info"
            aria-haspopup="true"
            aria-controls="dropdown-menu"
            @click="userDropDownActive = !userDropDownActive"
          >
            <span>{{ login.Alias }}</span>
            <span class="icon is-small">
              <i v-if="!userDropDownActive" class="fas fa-angle-down" aria-hidden="true"></i>
              <i v-if="userDropDownActive" class="fas fa-angle-up" aria-hidden="true"></i>
            </span>
          </button>
        </div>
        <div class="dropdown-menu has-text-left" id="dropdown-menu" role="menu">
          <div class="dropdown-content">
            <a href="#" class="dropdown-item has-icon" @click="showEditUser">
              <i class="fas fa-user-circle"></i>
              Edit User
            </a>
            <a href="#" class="dropdown-item has-icon" @click="showChangePassword">
              <i class="fas fa-key"></i>
              Change Password
            </a>
            <hr class="dropdown-divider" />
            <a href="#" class="dropdown-item has-icon" @click="logOut">
              <i class="fas fa-sign-out-alt"></i>
              Log Out
            </a>
          </div>
        </div>
      </div>
    </div>
    <router-view />
  </div>
  <div v-if="!isLoggedIn" style="max-width: 480px" class="mx-auto">
    <div class="content mt-4"><img src="@/assets/banner-logo.jpg" /></div>
    <LoginPanel v-if="loginChecked" />
  </div>

  <ChangePasswordModal
    v-if="changePasswordModalActive"
    :isActive="changePasswordModalActive"
    :isProcessing="changingPassword"
    v-on:modal-closed="changePasswordModalClosed"
  />

  <EditUserModal
    v-if="editUserModalActive"
    :isActive="editUserModalActive"
    :isProcessing="updatingUser"
    :currentEmail="currentEmail"
    :currentAlias="currentAlias"
    v-on:modal-closed="editUserModalClosed"
  />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import SchematrixApi from "@/services/schematrix";
import { createToast, ToastType } from "mosha-vue-toastify";
import LoginPanel from "./components/LoginPanel.vue";
import ChangePasswordModal from "@/components/ChangePasswordModal.vue";
import EditUserModal from "@/components/EditUserModal.vue";
import { store } from "./store";
import { mapGetters } from "vuex";
import { logger } from "@/services/utilities";
import config from "@/config/config.json";
import ModalResult from "./models/modal-result";
import ChangePasswordRequest from "@/models/change-password-request";
import LoginDTO from "@/models/login-dto";

@Options({
  data() {
    return {
      loginChecked: false,
      timerHandle: undefined,
      userDropDownActive: false,
      changePasswordModalActive: false,
      changingPassword: false,
      editUserModalActive: false,
      updatingUser: false,
    };
  },
  computed: {
    ...mapGetters(["isLoggedIn", "login"]),
    currentEmail() {
      return this.login.Email;
    },
    currentAlias() {
      return this.login.Alias;
    },
  },
  components: {
    LoginPanel,
    ChangePasswordModal,
    EditUserModal,
  },
  methods: {
    showToast(title: string, message: string, toastType: ToastType, timeout = 5000) {
      createToast(
        {
          title: title,
          description: message,
        },
        {
          type: toastType,
          timeout: timeout,
          position: "top-right",
          hideProgressBar: true,
          showIcon: true,
        }
      );
    },
    onError(error: never) {
      logger(error);
      this.showToast("Error", error, "danger");
    },
    logOut() {
      this.userDropDownActive = false;
      store.dispatch("logOut");
    },
    showChangePassword() {
      this.userDropDownActive = false;
      this.changePasswordModalActive = true;
    },
    changePasswordModalClosed(result: ModalResult<ChangePasswordRequest>) {
      if (result.cancelled) {
        this.changePasswordModalActive = false;
        return;
      } else {
        this.changingPassword = true;
        let request = result.data;
        request.Name = this.login.Name;
        SchematrixApi.changePassword(this.login.Token, request)
          .then(() => {
            logger(`Password changed for ${request.Name} created successfully`);
            this.showToast("Password Changed Successfully", undefined, "success", 3000);
          })
          .catch((error) => {
            this.onError(error);
          })
          .finally(() => {
            this.changingPassword = false;
            this.changePasswordModalActive = false;
          });
      }
    },
    showEditUser() {
      this.userDropDownActive = false;
      this.editUserModalActive = true;
    },
    editUserModalClosed(result: ModalResult<LoginDTO>) {
      if (result.cancelled) {
        this.editUserModalActive = false;
        return;
      } else {
        this.updatingUser = true;
        let request = result.data;
        request.Name = this.login.Name;
        SchematrixApi.updateUser(this.login.Token, request)
          .then(() => {
            logger(`User ${request.Name} updated successfully`);
            this.showToast("Account Saved", undefined, "success", 3000);
          })
          .catch((error) => {
            this.onError(error);
          })
          .finally(() => {
            this.updatingUser = false;
            this.editUserModalActive = false;
          });
      }
    },
    checkLoginStatus() {
      // logger("Checking login status: " + new Date().toLocaleString());
      var tokenDate = new Date(this.login.TokenExpiration);
      var nowDate = new Date();
      var tokenMilliSeconds = tokenDate.getTime() - nowDate.getTime();
      // logger(`Remaining token time: ${msToTime(tokenMilliSeconds)}`);
      // logger(`Remaining token ms: ${tokenMilliSeconds}`);
      if (tokenMilliSeconds < config.refreshTokenThreshold) {
        store.dispatch("refreshToken");
      }
    },
  },
  watch: {
    isLoggedIn: function (newVal) {
      logger("Login state changed to " + newVal);
      if (!newVal) {
        if (this.timerHandle !== undefined) {
          logger("Stopping check login timer");
          clearInterval(this.timerHandle);
          this.timerHandle = undefined;
        }
      } else {
        if (this.timerHandle === undefined && config.refreshTokens) {
          logger("Starting check login timer");
          this.timerHandle = setInterval(this.checkLoginStatus, config.tokenCheckPeriod);
        }
      }
    },
  },
  mounted() {
    store.dispatch("checkLogIn").then(() => {
      this.loginChecked = true;
    });
  },
})
export default class App extends Vue {}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 10px;
  a {
    &.router-link-exact-active {
      text-decoration: underline;
    }
  }
}
</style>
