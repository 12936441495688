
import { Options, Vue } from "vue-class-component";
import ModeEvent from "@/models/mode-event";
import LoginDTO from "@/models/login-dto";
import SchematrixApi from "@/services/schematrix";
import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";

class Fields {
  username = "";
  code = "";
  password = "";
  passwordConfirm = "";
}

class Errors {
  username?: string;
  code?: string;
  password?: string;
  passwordConfirm?: string;
}

@Options({
  emits: ["set-mode"],
  data() {
    return {
      fields: new Fields(),
      errors: new Errors(),
      serverError: undefined,
      processing: false,
    };
  },
  computed: {
    usernameOk() {
      return this.fields.username.trim().length > 0;
    },
    codeOk() {
      return this.fields.code.trim().length > 0;
    },
    passwordOk() {
      return this.fields.password.trim().length > 0;
    },
    passwordConfirmOk() {
      return this.fields.passwordConfirm.trim().length > 0 && this.fields.password === this.fields.passwordConfirm;
    },
  },
  methods: {
    validateForm(fields: Fields) {
      const errors = new Errors();
      if (!fields.username) errors.username = "User name is required.";
      if (!fields.code) errors.code = "Password reset code is required.";
      if (!fields.password) errors.password = "Password is required.";
      if (!fields.passwordConfirm) errors.passwordConfirm = "Confirm password is required.";
      if (fields.password !== fields.passwordConfirm) errors.passwordConfirm = "Passwords don't match.";
      return errors;
    },
    resetPassword(event: Event): void {
      event.preventDefault();
      this.errors = this.validateForm(this.fields);
      if (Object.keys(this.errors).length) return;
      let dto = new LoginDTO();
      dto.Name = this.fields.username;
      dto.Password = this.fields.password;
      dto.ResetCode = this.fields.code;
      this.serverError = undefined;
      this.processing = true;
      SchematrixApi.resetPassword(dto)
        .then(() => {
          this.serverError = undefined;
          createToast(
            {
              title: "Password Reset",
              description: "Please login with your new password.",
            },
            {
              type: "success",
              timeout: 10000,
              position: "top-right",
              hideProgressBar: true,
              showIcon: true,
            }
          );
          this.$emit("set-mode", new ModeEvent("login"));
        })
        .catch((error) => {
          this.serverError = error;
          this.$emit("set-mode", new ModeEvent("reset-password"));
        })
        .finally(() => {
          this.processing = false;
        });
    },
    clearError(event: MouseEvent): void {
      event.preventDefault();
      this.serverError = undefined;
    },
    setModeLogin(event: MouseEvent) {
      event.preventDefault();
      this.$emit("set-mode", new ModeEvent("login"));
    },
    setModeRegister(event: MouseEvent) {
      event.preventDefault();
      this.$emit("set-mode", new ModeEvent("register"));
    },
  },
})
export default class ResetPassword extends Vue {}
