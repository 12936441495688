class LoginDTO {
  public LoginId?: string;
  public Password?: string;
  public Code?: string;
  public ResetCode?: string;
  public Name?: string;
  public Alias?: string;
  public Email?: string;
  public IsEnabled = true;
  public IsAdmin = false;
  public GrantFlags = 0;
  public DenyFlags = 0;
  public CreateTime?: string;
  public Token?: string;
  public TokenExpiration?: string;
}

export default LoginDTO;
