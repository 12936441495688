
import { Options, Vue } from "vue-class-component";
import { mapGetters } from "vuex";
import Login from "./Login.vue";
import Register from "./Register.vue";
import RegisterConfirm from "./RegisterConfirm.vue";
import ForgotPassword from "./ForgotPassword.vue";
import ResetPassword from "./ResetPassword.vue";
import ResendRegistration from "./ResendRegistrationCode.vue";
import ModeEvent from "../models/mode-event";

@Options({
  data() {
    return {
      mode: "login",
    };
  },
  computed: {
    ...mapGetters([]),
  },
  methods: {
    setMode(modeEvent: ModeEvent) {
      this.mode = modeEvent.mode;
    },
  },
  components: { Login, Register, RegisterConfirm, ForgotPassword, ResetPassword, ResendRegistration },
})
export default class LoginPanel extends Vue {}
