
import { Options, Vue } from "vue-class-component";
import axios, { AxiosError } from "axios";
import { mapGetters } from "vuex";
import { store } from "@/store";
import { createToast, ToastType } from "mosha-vue-toastify";
import { getParentPath, logger, validContainerName, validFolderPath } from "@/services/utilities";
import { uploader, Upload, UploadStateCode, UploadResult } from "@/services/upload";
import mobileCheck from "@/services/mobilecheck";
import SchematrixApi from "@/services/schematrix";
import ContainerDTO from "@/models/container-dto";
import ContainerFolderDTO from "@/models/container-folder-dto";
import FileDropInfo from "@/models/file-drop-info";
import ManifestFileDTO from "@/models/manifest-file-dto";
import ModalResult from "@/models/modal-result";
import SignedUrlRequestDTO from "@/models/signed-url-request-dto";
import ContainerSelector from "@/components/ContainerSelector.vue";
import CreateContainerModal from "@/components/CreateContainerModal.vue";
import CreateFolderModal from "@/components/CreateFolderModal.vue";
import DeleteContainerModal from "@/components/DeleteContainerModal.vue";
import DeleteFileModal from "@/components/DeleteFileModal.vue";
import DeleteFolderModal from "@/components/DeleteFolderModal.vue";
import EditContainerModal from "@/components/EditContainerModal.vue";
import FileLinkModal from "@/components/FileLinkModal.vue";
import FileList from "@/components/FileList.vue";
import FolderTree from "@/components/FolderTree.vue";
import UploadList from "@/components/UploadList.vue";

@Options({
  components: {
    ContainerSelector,
    FolderTree,
    UploadList,
    FileList,
    DeleteFileModal,
    CreateContainerModal,
    EditContainerModal,
    DeleteContainerModal,
    CreateFolderModal,
    DeleteFolderModal,
    FileLinkModal,
  },
  data() {
    return {
      containerID: "null",
      containerName: "",
      folderPath: "",
      treeLoaded: false,
      loadingTree: false,
      uploadFileName: "",
      fileOver: false,
      activeUploads: new Array<Upload>(),
      folderFiles: new Array<ManifestFileDTO>(),
      showDeleteFileModal: false,
      deleteFileName: "",
      showCreateContainerModal: false,
      creatingContainer: false,
      showEditContainerModal: false,
      editContainerName: "",
      updatingContainer: false,
      showDeleteContainerModal: false,
      deleteContainerName: "",
      deleteContainerID: "",
      deletingContainer: false,
      showCreateFolderModal: false,
      creatingFolder: false,
      showDeleteFolderModal: false,
      deleteFolderPath: "",
      deletingFolder: false,
      showFileLinkModal: false,
      downloadFileLink: "",
      downloadFileName: "",
    };
  },
  computed: {
    ...mapGetters(["login", "containers"]),
    canUpload() {
      return this.treeLoaded;
    },
    isMobile() {
      return mobileCheck();
    },
  },
  methods: {
    showToast(title: string, message: string, toastType: ToastType, timeout = 5000) {
      createToast(
        {
          title: title,
          description: message,
        },
        {
          type: toastType,
          timeout: timeout,
          position: "top-right",
          hideProgressBar: true,
          showIcon: true,
        }
      );
    },
    onError(error: never) {
      logger(error);
      this.showToast("Error", error, "danger");
    },
    containerSelected(event: string) {
      const container = this.containers.find((c: ContainerDTO) => c.ContainerID === event);
      if (container) {
        if (container.ContainerID == this.containerId) return;
        this.treeLoaded = false;
        this.loadingTree = true;
        this.containerID = container.ContainerID;
        this.containerName = container.Name;
        logger("Container Selected: " + container.Name);
      } else {
        this.containerID = "null";
        this.containerName = "";
        logger("No Container Selected");
      }
    },
    promptToCreateContainer() {
      this.showCreateContainerModal = true;
    },
    createContainerModalClosed(result: ModalResult<string>) {
      if (result.cancelled) {
        this.showCreateContainerModal = false;
      } else {
        this.createContainer(result.data);
      }
    },
    createContainer(containerName: string) {
      if (!validContainerName(containerName)) {
        this.onError("Invalid container name");
        return;
      }
      let containerDTO = new ContainerDTO();
      containerDTO.Name = containerName;
      this.creatingContainer = true;
      SchematrixApi.createContainer(this.login.Token, containerDTO)
        .then((container) => {
          logger(`New container ${container.Name} created successfully`);
          this.showToast("Container Created", container.Name, "success", 3000);
          store.dispatch("refreshContainers", true).then(() => {
            this.containerID = container.ContainerID;
            this.containerName = container.Name;
          });
        })
        .catch((error) => {
          this.onError(error);
        })
        .finally(() => {
          this.showCreateContainerModal = false;
          this.creatingContainer = false;
        });
    },
    editContainerProperties() {
      this.editContainerName = this.containerName;
      this.showEditContainerModal = true;
    },
    editContainerModalClosed(result: ModalResult<string>) {
      if (result.cancelled) {
        this.showEditContainerModal = false;
      } else {
        this.editContainerName = result.data;
        this.commitContainerUpdate();
      }
    },
    commitContainerUpdate() {
      if (!validContainerName(this.editContainerName)) {
        this.onError("Invalid container name");
        return;
      }
      let containerDTO = new ContainerDTO();
      containerDTO.Name = this.editContainerName;
      containerDTO.ContainerID = this.containerID;
      this.updatingContainer = true;
      const oldContainerName = this.containerName;
      SchematrixApi.updateContainer(this.login.Token, containerDTO)
        .then((container) => {
          logger(`Container ${oldContainerName} renamed to ${this.editContainerName} updated successfully`);
          this.showToast("Container Updated", `${oldContainerName} renamed to ${this.editContainerName}`, "success", 3000);
          store.dispatch("refreshContainers", true).then(() => {
            this.containerID = container.ContainerID;
            this.containerName = container.Name;
          });
        })
        .catch((error) => {
          this.onError(error);
        })
        .finally(() => {
          this.showEditContainerModal = false;
          this.updatingContainer = false;
        });
    },
    promptToDeleteContainer() {
      this.deleteContainerName = this.containerName;
      this.deleteContainerID = this.containerID;
      this.showDeleteContainerModal = true;
    },
    deleteContainerModalClosed(result: ModalResult<string>) {
      if (result.cancelled) {
        this.showDeleteContainerModal = false;
      } else {
        this.deleteContainerConfirmed();
      }
    },
    deleteContainerConfirmed() {
      logger(`Delete container ${this.deleteContainerName} [${this.deleteContainerID}] + " confirmed`);
      this.deletingContainer = true;
      SchematrixApi.deleteContainer(this.login.Token, this.deleteContainerID)
        .then(() => {
          logger(`Container ${this.deleteContainerName} deleted successfully`);
          this.showToast("Container Deleted", this.deleteContainerName, "success", 5000);
          this.treeLoaded = false;
          this.containerID = "null";
          this.containerName = "";
          store.dispatch("refreshContainers", true);
        })
        .catch((error) => {
          this.onError(error);
        })
        .finally(() => {
          this.showDeleteContainerModal = false;
          this.deletingContainer = false;
        });
    },
    promptToCreateFolder() {
      this.showCreateFolderModal = true;
    },
    createFolderModalClosed(result: ModalResult<string>) {
      if (result.cancelled) {
        this.showCreateFolderModal = false;
      } else {
        this.createFolder(result.data);
      }
    },
    createFolder(newFolderPath: string) {
      if (!validFolderPath(newFolderPath)) {
        this.onError("Invalid folder path");
        return;
      }
      let containerFolderDTO = new ContainerFolderDTO();
      containerFolderDTO.ContainerID = this.containerID;
      let newPath = "";
      if (newFolderPath && newFolderPath.charAt(0) === "/") {
        newPath = newFolderPath;
      } else {
        newPath = this.folderPath + newFolderPath;
      }
      if (newPath.charAt(newPath.length - 1) !== "/") {
        newPath += "/";
      }
      containerFolderDTO.Path = newPath;
      this.creatingFolder = true;
      SchematrixApi.createFolder(this.login.Token, containerFolderDTO)
        .then((containerFolder) => {
          logger(`New folder ${containerFolder.Path} created successfully`);
          this.showToast("Folder Created", newPath, "success", 3000);
          this.folderPath = containerFolderDTO.Path;
          this.$refs.folderTree.refresh(containerFolderDTO.Path);
          this.listFolderFiles(this.containerID, this.folderPath);
        })
        .catch((error) => {
          this.onError(error);
        })
        .finally(() => {
          this.showCreateFolderModal = false;
          this.creatingFolder = false;
        });
    },
    folderSelected(event: string) {
      logger("Folder selected: " + event);
      this.folderPath = event;
      this.listFolderFiles(this.containerID, this.folderPath);
    },
    promptToDeleteFolder() {
      this.deleteFolderPath = this.folderPath;
      this.showDeleteFolderModal = true;
    },
    deleteFolderModalClosed(result: ModalResult<string>) {
      if (result.cancelled) {
        this.showDeleteFolderModal = false;
      } else {
        this.deleteFolderConfirmed();
      }
    },
    deleteFolderConfirmed() {
      logger(`Delete folder ${this.deleteFolderPath} [${this.containerID}] + " confirmed`);
      this.deletingFolder = true;
      SchematrixApi.deleteFolder(this.login.Token, this.containerID, this.deleteFolderPath)
        .then(() => {
          logger(`Folder ${this.deleteFolderPath} deleted successfully`);
          this.showToast("Folder Deleted", this.deleteFolderPath, "success", 5000);
          this.$refs.folderTree.refresh(getParentPath(this.deleteFolderPath));
          this.listFolderFiles(this.containerID, this.folderPath);
        })
        .catch((error) => {
          this.onError(error);
        })
        .finally(() => {
          this.showDeleteFolderModal = false;
          this.deletingFolder = false;
        });
    },
    uploadFileSelected(event: Event) {
      let files = (event.target as HTMLInputElement).files;
      if (files !== null) {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          logger(`File ${file.name} queued for upload.`);
          this.queueUpload(file);
        }
      }
    },
    listFolderFiles(containerID: string, folderPath: string) {
      logger(`Retrieving manifest for ${folderPath} in container ${containerID}`);
      SchematrixApi.getContainerManifest(this.login.Token, containerID, false, folderPath, true, false, false)
        .then((manifest) => {
          let folderFiles: ManifestFileDTO[] = [];
          if (manifest.Files) {
            manifest.Files.forEach((file) => {
              folderFiles.push(file);
            });
          }
          this.folderFiles = folderFiles;
        })
        .catch((error) => {
          this.onError(error);
        });
    },
    uploadFile(file: File, containerID: string, folderPath: string) {
      const urlRequest = new SignedUrlRequestDTO();
      urlRequest.ContainerID = containerID;
      urlRequest.Path = folderPath + file.name;
      urlRequest.ContentType = file.type;
      urlRequest.Verb = "put";
      SchematrixApi.getSignedUrl(this.login.Token, urlRequest)
        .then((response) => {
          // Start upload using signed URL
          const upload = new Upload(file.name, file.type, file.size, response.Url);
          upload.containerID = containerID;
          upload.folderPath = folderPath;
          upload.file = file;
          upload.removeOnFailure = true;
          upload.removeOnSuccess = true;
          upload.callback.add((result: UploadResult) => {
            if (result.success) {
              logger(`Upload ${file.name} callback: Success`);
              createToast(
                {
                  title: "Upload Complete",
                  description: file.name,
                },
                {
                  type: "success",
                  timeout: 2000,
                  position: "top-right",
                  hideProgressBar: true,
                  showIcon: true,
                }
              );
              if (upload.containerID === this.containerID && upload.folderPath === this.folderPath) {
                this.listFolderFiles(this.containerID, this.folderPath);
              }
            } else {
              if (upload.state.code === UploadStateCode.FAILED) {
                this.onError(`Upload of ${upload.name} failed.`);
              }
              if (upload.state.code === UploadStateCode.ABORTED) {
                this.onError(`Upload of ${upload.name} was aborted.`);
              }
            }
            const index = this.activeUploads.indexOf(upload);
            if (index !== -1) {
              this.activeUploads.splice(index, 1);
            }
          });
          // upload.state = new UploadState(UploadStateCode.QUEUED, 50, '');
          uploader.queue(upload);
        })
        .catch((error) => {
          this.onError(error);
        });
    },
    cancelUpload(upload: Upload) {
      logger("Cancel upload " + upload.name);
      if (upload.id !== undefined) {
        uploader.removeById(upload.id);
      }
    },
    queueUpload(file: File) {
      this.uploadFile(file, this.containerID, this.folderPath);
    },
    uploadQueued(upload: Upload) {
      logger(`Upload queued: ${upload.name}`);
      if (upload.containerID === this.containerID && upload.folderPath === this.folderPath) {
        this.activeUploads.push(upload);
      }
    },
    uploadStarted(upload: Upload) {
      logger(`Upload started: ${upload.name}`);
    },
    uploadCompleted(upload: Upload) {
      logger(`Upload completed: ${upload.name}`);
    },
    uploadFailed(upload: Upload) {
      logger(`Upload failed: ${upload.name}`);
    },
    uploadAborted(upload: Upload) {
      logger(`Upload aborted: ${upload.name}`);
    },
    uploadRemoved(upload: Upload) {
      logger(`Upload removed: ${upload.name}`);
      const index = this.activeUploads.indexOf(upload);
      if (index !== -1) {
        this.activeUploads.splice(index, 1);
      }
    },
    promptToDeleteFile(file: ManifestFileDTO, showConfirmationPrompt = true) {
      logger("Delete " + file.Name);
      this.deleteFileName = file.Name;
      if (showConfirmationPrompt) {
        this.showDeleteFileModal = true;
      } else {
        logger("Triggering immediate delete of " + this.deleteFileName);
        this.deleteFile(this.deleteFileName);
      }
    },
    deleteFileModalClosed(result: ModalResult<string>) {
      if (result.cancelled) {
        this.showDeleteFileModal = false;
      } else {
        this.deleteFile(result.data);
      }
    },
    deleteFile(filename: string) {
      logger("Delete " + filename + " confirmed");
      const urlRequest = new SignedUrlRequestDTO();
      urlRequest.ContainerID = this.containerID;
      urlRequest.Path = this.folderPath + filename;
      urlRequest.Verb = "delete";
      SchematrixApi.getSignedUrl(this.login.Token, urlRequest)
        .then((response) => {
          axios
            .delete(response.Url)
            .then(() => {
              this.showToast("File Deleted", filename, "success", 2000);
              this.listFolderFiles(this.containerID, this.folderPath);
            })
            .catch((error: AxiosError) => {
              this.onError(`Unable to delete ${filename}. ${error.message}`);
            })
            .finally(() => {
              this.showDeleteFileModal = false;
            });
        })
        .catch((error) => {
          this.onError(error);
        });
    },
    selectFile(file: ManifestFileDTO) {
      if (this.isMobile) {
        this.selectFileMobile(file);
        return;
      }
      const urlRequest = new SignedUrlRequestDTO();
      urlRequest.ContainerID = this.containerID;
      urlRequest.Path = this.folderPath + file.Name;
      urlRequest.ContentDisposition = 'filename="' + file.Name + '"';
      urlRequest.Verb = "get";
      SchematrixApi.getSignedUrl(this.login.Token, urlRequest)
        .then((response) => {
          window.open(response.Url);
        })
        .catch((error) => {
          this.onError(error);
        });
    },
    selectFileMobile(file: ManifestFileDTO) {
      const urlRequest = new SignedUrlRequestDTO();
      const newWindow = window.open("", "_blank");
      if (newWindow == null) {
        this.onError("Unable to open new window to display content.");
        return;
      }
      urlRequest.ContainerID = this.containerID;
      urlRequest.Path = this.folderPath + file.Name;
      urlRequest.ContentDisposition = 'filename="' + file.Name + '"';
      urlRequest.Verb = "get";
      SchematrixApi.getSignedUrl(this.login.Token, urlRequest)
        .then((response) => {
          newWindow.location.href = response.Url;
        })
        .catch((error) => {
          this.onError(error);
          newWindow.close();
        });
    },
    copyFileLink(file: ManifestFileDTO) {
      if (this.isMobile) {
        this.copyFileLinkMobile(file);
        return;
      }
      const urlRequest = new SignedUrlRequestDTO();
      urlRequest.ContainerID = this.containerID;
      urlRequest.Path = this.folderPath + file.Name;
      urlRequest.ContentDisposition = 'attachment;filename="' + file.Name + '"';
      urlRequest.Verb = "get";
      SchematrixApi.getSignedUrl(this.login.Token, urlRequest)
        .then((response) => {
          if (!navigator.clipboard) {
            window.prompt("Copy to clipboard: Ctrl+C, Enter", response.Url);
            return;
          }
          navigator.clipboard.writeText(response.Url).then(() => {
            this.showToast("Link Copied To Clipboard", file.Name, "success", 3000);
          });
        })
        .catch((error) => {
          this.onError(error);
        });
    },
    copyFileLinkMobile(file: ManifestFileDTO) {
      const urlRequest = new SignedUrlRequestDTO();
      urlRequest.ContainerID = this.containerID;
      urlRequest.Path = this.folderPath + file.Name;
      urlRequest.ContentDisposition = 'attachment;filename="' + file.Name + '"';
      urlRequest.Verb = "get";
      SchematrixApi.getSignedUrl(this.login.Token, urlRequest)
        .then((response) => {
          this.downloadFileLink = response.Url;
          this.downloadFileName = file.Name;
          this.showFileLinkModal = true;
        })
        .catch((error) => {
          this.onError(error);
        });
    },
    downloadFile(file: ManifestFileDTO) {
      if (this.isMobile) {
        this.downloadFileMobile(file);
        return;
      }
      const urlRequest = new SignedUrlRequestDTO();
      urlRequest.ContainerID = this.containerID;
      urlRequest.Path = this.folderPath + file.Name;
      urlRequest.ContentDisposition = 'attachment;filename="' + file.Name + '"';
      urlRequest.Verb = "get";
      SchematrixApi.getSignedUrl(this.login.Token, urlRequest)
        .then((response) => {
          window.open(response.Url);
        })
        .catch((error) => {
          this.onError(error);
        });
    },
    downloadFileMobile(file: ManifestFileDTO) {
      const newWindow = window.open("", "_blank");
      if (newWindow == null) {
        this.onError("Unable to open new window to display content.");
        return;
      }
      const urlRequest = new SignedUrlRequestDTO();
      urlRequest.ContainerID = this.containerID;
      urlRequest.Path = this.folderPath + file.Name;
      urlRequest.ContentDisposition = 'attachment;filename="' + file.Name + '"';
      urlRequest.Verb = "get";
      SchematrixApi.getSignedUrl(this.login.Token, urlRequest)
        .then((response) => {
          newWindow.location.href = response.Url;
        })
        .catch((error) => {
          newWindow.close();
          this.onError(error);
        });
    },
    onDragOver(event: DragEvent) {
      event.preventDefault();
      event.stopPropagation();
      this.fileOver = true;
    },
    onDragLeave(event: DragEvent) {
      event.preventDefault();
      event.stopPropagation();
      this.fileOver = false;
    },
    onDrop(event: DragEvent) {
      event.preventDefault();
      event.stopPropagation();
      this.fileOver = false;
      if (event.dataTransfer === null) {
        return;
      }
      if (event.dataTransfer.files === null) {
        return;
      }
      const files = event.dataTransfer.files;
      if (files.length > 0) {
        const fileDropInfo = new FileDropInfo(this.folderPath, files);
        this.onFilesDropped(fileDropInfo);
      }
    },
    onFilesDropped(event: FileDropInfo) {
      for (let i = 0; i < event.files.length; i++) {
        this.uploadFile(event.files[i], this.containerID, event.folderPath);
      }
    },
  },
  mounted() {
    /*
    if (this.isMobile) {
      this.showToast("Mobile browser");
    } else {
      this.showToast("Not mobile browser");
    }
    */
    uploader.uploadQueued.add(this.uploadQueued);
    uploader.uploadStarted.add(this.uploadStarted);
    uploader.uploadCompleted.add(this.uploadCompleted);
    uploader.uploadFailed.add(this.uploadFailed);
    uploader.uploadAborted.add(this.uploadAborted);
    uploader.uploadRemoved.add(this.uploadRemoved);
    uploader.uploads.forEach((u) => {
      if (u.containerID === this.containerID && u.folderPath == this.folderPath) {
        this.activeUploads.push(u);
      }
    });
  },
  beforeUnmount() {
    uploader.uploadQueued.remove(this.uploadQueued);
    uploader.uploadStarted.remove(this.uploadStarted);
    uploader.uploadCompleted.remove(this.uploadCompleted);
    uploader.uploadFailed.remove(this.uploadFailed);
    uploader.uploadAborted.remove(this.uploadAborted);
    uploader.uploadRemoved.remove(this.uploadRemoved);
  },
})
export default class Home extends Vue {}
