<template>
  <div class="select">
    <select v-bind:value="containerID" v-on:input="$emit('input', $event.target.value)">
      <option disabled value="null">[ Select Container ]</option>
      <option v-for="container in containers" v-bind:key="container.ContainerID" :value="container.ContainerID">
        {{ container.Name }}
      </option>
    </select>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { store } from "../store";
import { mapGetters } from "vuex";
import { logger } from "@/services/utilities";

@Options({
  props: ["containerID"],
  emits: ["input"],
  computed: {
    ...mapGetters(["containers"]),
  },
  mounted() {
    store.dispatch("refreshContainers", false).then(() => {
      logger("Containers loaded.");
    });
  },
})
export default class ContainerSelector extends Vue {}
</script>

<style scoped>
.select,
.select select {
  width: 100%;
}
</style>
