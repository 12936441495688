
import { Options, Vue } from "vue-class-component";
import { formatBytes } from "@/services/utilities";
import { uploader } from "@/services/upload";

@Options({
  props: ["uploads"],
  emits: ["cancel"],
  methods: {
    innerFormatBytes(bytes: number, decimals: number) {
      return formatBytes(bytes, decimals);
    },
    uploadProgress() {
      this.$forceUpdate();
    },
  },
  mounted() {
    uploader.uploadProgress.add(this.uploadProgress);
  },
  beforeUnmount() {
    uploader.uploadProgress.remove(this.uploadProgress);
  },
})
export default class UploadList extends Vue {}
