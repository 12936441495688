
import ChangePasswordRequest from "@/models/change-password-request";
import ModalResult from "@/models/modal-result";
import { Options, Vue } from "vue-class-component";
@Options({
  data() {
    return {
      username: "",
      currentPassword: "",
      currentPasswordErrors: "",
      currentPasswordInput: false,
      newPassword: "",
      newPasswordErrors: "",
      newPasswordInput: false,
      newPasswordConfirm: "",
      newPasswordConfirmErrors: "",
      newPasswordConfirmInput: false,
    };
  },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    isProcessing: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currentPasswordOk() {
      return this.currentPassword.trim().length > 0;
    },
    newPasswordOk() {
      return this.newPassword.trim().length > 0;
    },
    newPasswordConfirmOk() {
      return this.newPasswordConfirm.trim().length > 0 && this.newPasswordConfirm === this.newPassword;
    },
    formOk() {
      return this.validateForm();
    },
  },
  emits: ["modal-closed"],
  methods: {
    validateForm() {
      this.currentPasswordErrors = "";
      this.newPasswordErrors = "";
      this.newPasswordConfirmErrors = "";
      if (!this.currentPassword) this.currentPasswordErrors = "Current password is required.";
      if (!this.newPassword) this.newPasswordErrors = "New password is required.";
      if (!this.newPasswordConfirm) this.newPasswordConfirmErrors = "New password confirm is required.";
      if (this.newPassword !== this.newPasswordConfirm)
        this.newPasswordConfirmErrors = "New Password and new password confirm don't match.";
      return (
        this.currentPasswordInput &&
        this.newPasswordInput &&
        this.newPasswordConfirmInput &&
        !(this.currentPasswordErrors || this.newPasswordErrors || this.newPasswordConfirmErrors)
      );
    },
    onOk() {
      let request = new ChangePasswordRequest();
      request.Password = this.currentPassword;
      request.NewPassword = this.newPassword;
      this.$emit("modal-closed", new ModalResult(false, request));
    },
    onCancel() {
      this.$emit("modal-closed", new ModalResult(true, undefined));
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.currentPassword.focus();
    });
  },
})
export default class ChangePasswordModal extends Vue {}
