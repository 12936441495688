
import { Options, Vue } from "vue-class-component";
import { validContainerName } from "@/services/utilities";
import ModalResult from "@/models/modal-result";
@Options({
  data() {
    return {
      name: this.containerName,
    };
  },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    isProcessing: {
      type: Boolean,
      default: false,
    },
    containerName: {
      type: String,
      default: "",
    },
  },
  emits: ["modal-closed"],
  computed: {
    isValidContainerName() {
      return validContainerName(this.name);
    },
  },
  methods: {
    onOk() {
      this.$emit("modal-closed", new ModalResult<string>(false, this.name));
    },
    onCancel() {
      this.$emit("modal-closed", new ModalResult<string>(true, ""));
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.name.focus();
    });
  },
})
export default class EditContainerModal extends Vue {}
