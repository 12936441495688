<template>
  <div class="notification is-danger" v-if="serverError">
    <button class="delete" @click="clearError"></button>
    {{ serverError }}
  </div>
  <nav class="panel is-info">
    <p class="panel-heading">Resend Registration Code</p>
    <div class="notification is-info is-light m-2">
      Enter your login name or email address to resend your registration confirmation code.
    </div>
    <div class="panel-block">
      <form style="width: 100%" @submit="requestPasswordReset">
        <div class="field">
          <label class="label has-text-left">Login Name or Email Address</label>
          <div class="control">
            <input class="input" type="text" v-model="fields.username" :disabled="processing" />
          </div>
          <div style="color: red" class="has-text-left" v-if="!usernameOk">
            {{ errors.username }}
          </div>
        </div>
        <button class="button mt-3 mx-1 is-info" v-bind:class="{ 'is-loading': processing }">Resend Confirmation Code</button>
      </form>
    </div>
  </nav>
  <a @click="setModeLogin">Log into existing account</a>
  <br />
  <a @click="setModeRegisterConfirm">Confirm a pending registration</a>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import ModeEvent from "../models/mode-event";
import SchematrixApi from "@/services/schematrix";
import LoginDTO from "../models/login-dto";
import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";

class Fields {
  username = "";
}

class Errors {
  username?: string;
}

@Options({
  emits: ["set-mode"],
  data() {
    return {
      fields: new Fields(),
      errors: new Errors(),
      processing: false,
      serverError: undefined,
    };
  },
  computed: {
    usernameOk() {
      return this.fields.username.trim().length > 0;
    },
    codeOk() {
      return this.fields.code.trim().length > 0;
    },
  },
  methods: {
    validateForm(fields: Fields) {
      const errors = new Errors();
      if (!fields.username) errors.username = "User name or email address is required.";
      return errors;
    },
    requestPasswordReset(event: Event): void {
      event.preventDefault();
      this.errors = this.validateForm(this.fields);
      if (Object.keys(this.errors).length) return;
      let dto = new LoginDTO();
      dto.Name = this.fields.username;
      this.serverError = undefined;
      this.processing = true;
      SchematrixApi.resendRegistrationCode(dto)
        .then(() => {
          this.serverError = undefined;
          createToast(
            {
              title: "Code Resent",
              description: "Please check your email and confirm your registration with the code received.",
            },
            {
              type: "success",
              timeout: 10000,
              position: "top-right",
              hideProgressBar: true,
              showIcon: true,
            }
          );
          this.$emit("set-mode", new ModeEvent("register-confirm"));
        })
        .catch((error) => {
          this.serverError = error;
          this.$emit("set-mode", new ModeEvent("resend-registration"));
        })
        .finally(() => {
          this.processing = false;
        });
    },
    clearError(event: MouseEvent): void {
      event.preventDefault();
      this.serverError = undefined;
    },
    setModeLogin(event: MouseEvent) {
      event.preventDefault();
      this.$emit("set-mode", new ModeEvent("login"));
    },
    setModeRegisterConfirm(event: MouseEvent) {
      event.preventDefault();
      this.$emit("set-mode", new ModeEvent("register-confirm"));
    },
  },
})
export default class ResendRegistartionCode extends Vue {}
</script>
