
import { Options, Vue } from "vue-class-component";
import { store } from "../store";
import SchematrixApi from "../services/schematrix";
import LoginRequest from "../models/login-request";
import ModeEvent from "../models/mode-event";

class Fields {
  username = "";
  password = "";
}

class Errors {
  username?: string;
  password?: string;
}

@Options({
  emits: ["set-mode"],
  data() {
    return {
      fields: new Fields(),
      errors: new Errors(),
      serverError: undefined,
      processing: false,
    };
  },
  computed: {
    usernameOk() {
      return this.fields.username.trim().length > 0;
    },
    passwordOk() {
      return this.fields.password.trim().length > 0;
    },
  },
  methods: {
    validateForm(fields: Fields) {
      const errors = new Errors();
      if (!fields.username) errors.username = "User name or email is required.";
      if (!fields.password) errors.password = "Password is required.";
      return errors;
    },
    logIn(event: Event): void {
      event.preventDefault();
      this.errors = this.validateForm(this.fields);
      if (Object.keys(this.errors).length) return;
      this.serverError = undefined;
      this.processing = true;
      SchematrixApi.login(new LoginRequest(this.fields.username, this.fields.password))
        .then((loginDTO) => {
          this.serverError = undefined;
          store.dispatch("setLogin", loginDTO);
        })
        .catch((error) => {
          this.serverError = error;
          store.dispatch("setLogin", undefined);
        })
        .finally(() => {
          this.processing = false;
        });
    },
    clearError(event: MouseEvent): void {
      event.preventDefault();
      this.serverError = undefined;
    },
    setModeForgotPassword(event: MouseEvent) {
      event.preventDefault();
      this.$emit("set-mode", new ModeEvent("forgot-password"));
    },
    setModeRegister(event: MouseEvent) {
      event.preventDefault();
      this.$emit("set-mode", new ModeEvent("register"));
    },
  },
})
export default class Login extends Vue {}
