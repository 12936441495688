
import { Options, Vue } from "vue-class-component";
import { store } from "../store";
import { mapGetters } from "vuex";
import { logger } from "@/services/utilities";

@Options({
  props: ["containerID"],
  emits: ["input"],
  computed: {
    ...mapGetters(["containers"]),
  },
  mounted() {
    store.dispatch("refreshContainers", false).then(() => {
      logger("Containers loaded.");
    });
  },
})
export default class ContainerSelector extends Vue {}
