<template>
  <div
    v-bind:class="{ 'is-active': isActive }"
    class="modal"
    tabindex="0"
    style="user-select: none"
    ref="modal"
    v-on:keyup.esc="onCancel"
  >
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Confirm Container Deletion</p>
        <button class="delete" aria-label="close" @click="onCancel"></button>
      </header>
      <section class="modal-card-body">
        <div class="notification is-danger">This is an immediate, permanent operation and cannot be reversed.</div>
        <div class="mt-2">
          Are you absolutely sure you want to delete container
          <strong>'{{ containerName }}'</strong>
          and all its contents?
        </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-danger" @click="onOk" v-bind:class="{ 'is-loading': isProcessing }">Yes, Delete</button>
        <button class="button" @click="onCancel">Cancel</button>
      </footer>
    </div>
  </div>
</template>

<script lang="ts">
import ModalResult from "@/models/modal-result";
import { Options, Vue } from "vue-class-component";
@Options({
  props: {
    containerName: {
      type: String,
      default: "",
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    isProcessing: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["modal-closed"],
  methods: {
    onOk() {
      this.$emit("modal-closed", new ModalResult(false, this.containerName));
    },
    onCancel() {
      this.$emit("modal-closed", new ModalResult(true, this.containerName));
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.modal.focus();
    });
  },
})
export default class DeleteContainerModal extends Vue {}
</script>
