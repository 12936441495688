<template>
  <div class="files-list" v-if="uploads && uploads.length > 0">
    <div class="single-file my-2" v-for="upload in uploads" v-bind:key="upload.id">
      <div class="info">
        <span class="name is-pulled-left">
          {{ upload.name }}
        </span>
        <span class="status is-pulled-left">{{ upload.statusName() }}</span>
        <span class="is-pulled-right ml-2" @click="$emit('cancel', upload)">
          <button class="delete is-small" style="background-color: #ff6e6e !important" title="Cancel Upload"></button>
        </span>
        <span class="size is-pulled-right" v-html="innerFormatBytes(upload.size)"></span>
        <progress class="progress is-info" :value="upload.state.percent" max="100">{{ upload.state.percent }}%</progress>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { formatBytes } from "@/services/utilities";
import { uploader } from "@/services/upload";

@Options({
  props: ["uploads"],
  emits: ["cancel"],
  methods: {
    innerFormatBytes(bytes: number, decimals: number) {
      return formatBytes(bytes, decimals);
    },
    uploadProgress() {
      this.$forceUpdate();
    },
  },
  mounted() {
    uploader.uploadProgress.add(this.uploadProgress);
  },
  beforeUnmount() {
    uploader.uploadProgress.remove(this.uploadProgress);
  },
})
export default class UploadList extends Vue {}
</script>

<style lang="scss" scoped>
.files-list {
  margin-top: 0;

  .single-file {
    margin-bottom: 0;

    .name {
      font-size: 14px;
      font-weight: 500;
      color: #353f4a;
      margin: 0;
    }

    .status {
      color: #7d8185;
      font-size: 14px;
      margin-left: 4px;
    }

    .status::before {
      content: "[";
    }

    .status::after {
      content: "]";
    }

    .size {
      font-size: 12px;
      font-weight: 500;
      color: #a4a4a4;
      margin: 0;
      margin-bottom: 0.25rem;
      display: inline-block;
    }

    .info {
      width: 100%;
    }
  }
}
</style>
