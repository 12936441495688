export class UploadService {
  uploads: Upload[] = [];

  uploadQueued: Event<Upload> = new Event();
  uploadForced: Event<Upload> = new Event();
  uploadRemoved: Event<Upload> = new Event();
  uploadStarted: Event<Upload> = new Event();
  uploadCompleted: Event<Upload> = new Event();
  uploadAborted: Event<Upload> = new Event();
  uploadFailed: Event<Upload> = new Event();
  uploadProgress: Event<Upload> = new Event();
  enabled = true;
  uploadCounter = 0;
  maxActive = 4;
  activeUploads = 0;

  queue = (upload: Upload) => {
    upload.state = UploadState.create(UploadStateCode.QUEUED, 0, "Queued");
    upload.id = this.uploadCounter++;
    this.uploads.push(upload);
    this.uploadQueued.trigger(upload);
    this.beginNext();
  };

  immediate = (upload: Upload) => {
    upload.state = UploadState.create(UploadStateCode.UPLOADING, 0, "Immediate Upload");
    upload.id = this.uploadCounter++;
    upload.isImmediate = true;

    // Insert after completed and any other immediate, but before any queued
    let i = 0,
      index = 0;
    while (i < this.uploads.length) {
      const u = this.uploads[i];
      const code = u.state.code;
      if (!u.isImmediate && code === UploadStateCode.QUEUED) {
        break;
      }
      i++;
      index++;
    }

    this.uploads.splice(index, 0, upload);

    // Call upload forced event
    this.uploadForced.trigger(upload);

    // Create XMLHttpRequest for upload
    const xhr = new XMLHttpRequest();
    upload.request = xhr;

    // Attach to monitor upload progress
    xhr.upload.addEventListener(
      "progress",
      (evt) => {
        if (evt.lengthComputable) {
          const percent = (evt.loaded / evt.total) * 100;
          upload.state = UploadState.create(UploadStateCode.UPLOADING, percent, upload.name + " [" + percent + "%]");
          this.uploadProgress.trigger(upload);
        }
      },
      false
    );

    // Monitor file completion
    xhr.addEventListener(
      "load",
      () => {
        upload.state = UploadState.create(UploadStateCode.COMPLETED, 100, upload.name + " completed successfully.");

        // If status is in 400-500 range, report as error
        if (xhr.status >= 400) {
          upload.state = UploadState.create(
            UploadStateCode.FAILED,
            upload.state.percent,
            upload.name + " failed. " + xhr.statusText
          );
          this.onUploadFailed(upload);
          upload.callback.trigger(new UploadResult(upload, false));
          if (upload.removeOnFailure) {
            this.remove(upload);
          }
        } else {
          this.onUploadCompleted(upload);
          upload.callback.trigger(new UploadResult(upload, true));
          if (upload.removeOnSuccess) {
            this.remove(upload);
          }
        }
      },
      false
    );

    // Monitor file cancellation
    xhr.addEventListener(
      "abort",
      () => {
        upload.state = UploadState.create(
          UploadStateCode.ABORTED,
          upload.state.percent,
          upload.name + " aborted. " + xhr.statusText
        );
        this.onUploadAborted(upload);
        upload.callback.trigger(new UploadResult(upload, false));
        if (upload.removeOnFailure) {
          this.remove(upload);
        }
      },
      false
    );

    // Monitor upload failure
    xhr.addEventListener(
      "error",
      () => {
        upload.state = UploadState.create(
          UploadStateCode.FAILED,
          upload.state.percent,
          upload.name + " failed. " + xhr.statusText
        );
        this.onUploadFailed(upload);
        upload.callback.trigger(new UploadResult(upload, false));
        if (upload.removeOnFailure) {
          this.remove(upload);
        }
      },
      false
    );

    // Open request and set any assigned headers
    xhr.open(upload.method, upload.url, true);
    upload.headers.forEach((header) => {
      xhr.setRequestHeader(header.name, header.value);
    });

    // Increment active uploads
    this.activeUploads++;

    // Set upload state to uploading
    upload.state = UploadState.create(UploadStateCode.UPLOADING, 0, upload.name + " starting.");

    // Call start event
    this.onUploadStarted(upload);

    // Upload file or data
    let blob;
    if (upload.file) {
      // If file upload
      if (upload.type) {
        xhr.setRequestHeader("content-type", upload.type);
      }
      xhr.send(upload.file);
    } else if (upload.data) {
      // Data (blob) upload
      if (upload.type) {
        blob = new Blob([upload.data], { type: upload.type });
        xhr.setRequestHeader("content-type", upload.type);
      } else {
        blob = new Blob([upload.data], { type: "" });
      }
      xhr.send(blob);
    }
  };

  start = (): void => {
    this.enabled = true;
    this.beginNext();
  };

  pause = (): void => {
    this.enabled = false;
  };

  remove = (upload: Upload) => {
    const index = this.uploads.indexOf(upload);
    if (index !== -1) {
      if (upload.state.code === UploadStateCode.UPLOADING) {
        upload.request?.abort();
      }
      this.uploads.splice(index, 1);
      this.uploadRemoved.trigger(upload);
    }
  };

  removeById = (id: number) => {
    let found = null;
    this.uploads.forEach((upload) => {
      if (upload.id === id) {
        found = upload;
      }
    });
    if (found) {
      this.remove(found);
    }
  };

  removeFinished = () => {
    const finished: Upload[] = [];
    this.uploads.forEach((upload) => {
      switch (upload.state.code) {
        case UploadStateCode.COMPLETED:
        case UploadStateCode.ABORTED:
        case UploadStateCode.FAILED:
          finished.push(upload);
          break;
      }
    });
    finished.forEach((upload) => {
      this.remove(upload);
    });
  };

  removeAll = () => {
    const trash: Upload[] = [];
    this.uploads.forEach((upload) => {
      trash.push(upload);
    });
    trash.forEach((upload) => {
      this.remove(upload);
    });
  };

  onUploadProgress = (e: Upload) => {
    this.uploadProgress.trigger(e);
  };

  onUploadStarted = (upload: Upload) => {
    this.uploadStarted.trigger(upload);
  };

  onUploadCompleted = (upload: Upload) => {
    this.activeUploads--;
    this.uploadCompleted.trigger(upload);
    this.beginNext();
  };

  onUploadAborted = (upload: Upload) => {
    this.activeUploads--;
    this.uploadAborted.trigger(upload);
    this.beginNext();
  };

  onUploadFailed = (upload: Upload) => {
    this.activeUploads--;
    this.uploadFailed.trigger(upload);
    this.beginNext();
  };

  beginNext = () => {
    // Exit if disabled
    if (!this.enabled) {
      return;
    }

    // Exit if max active reached
    if (this.activeUploads >= this.maxActive) {
      return;
    }

    // Look for next file to upload
    let nextUpload = null,
      upload: Upload;
    const l = this.uploads.length;
    for (let i = 0; i < l; i++) {
      upload = this.uploads[i];
      if (upload.state.code === UploadStateCode.QUEUED) {
        nextUpload = upload;
        break;
      }
    }

    // Return if nothing to upload
    if (nextUpload === null) {
      return;
    }

    // Create XMLHttpRequest for upload
    const xhr = new XMLHttpRequest();
    nextUpload.request = xhr;

    // Attach to monitor upload progress
    xhr.upload.addEventListener(
      "progress",
      (evt) => {
        if (evt.lengthComputable) {
          const percent = (evt.loaded / evt.total) * 100;
          upload.state = UploadState.create(UploadStateCode.UPLOADING, percent, upload.name + " [" + percent + "%]");
          this.onUploadProgress(upload);
        }
      },
      false
    );

    // Monitor file completion
    xhr.addEventListener(
      "load",
      () => {
        upload.state = UploadState.create(UploadStateCode.COMPLETED, 100, upload.name + " completed successfully.");

        // If status is in 400-500 range, report as error
        if (xhr.status >= 400) {
          upload.state = UploadState.create(
            UploadStateCode.FAILED,
            upload.state.percent,
            upload.name + " failed. " + xhr.statusText
          );
          this.onUploadFailed(upload);
          upload.callback.trigger(new UploadResult(upload, false));
          if (upload.removeOnFailure) {
            this.remove(upload);
          }
        } else {
          this.onUploadCompleted(upload);
          upload.callback.trigger(new UploadResult(upload, true));
          if (upload.removeOnSuccess) {
            this.remove(upload);
          }
        }
      },
      false
    );

    // Monitor file cancellation
    xhr.addEventListener(
      "abort",
      () => {
        upload.state = UploadState.create(
          UploadStateCode.ABORTED,
          upload.state.percent,
          upload.name + " aborted. " + xhr.statusText
        );
        this.onUploadAborted(upload);
        upload.callback.trigger(new UploadResult(upload, false));
        if (upload.removeOnFailure) {
          this.remove(upload);
        }
      },
      false
    );

    // Monitor upload failure
    xhr.addEventListener(
      "error",
      () => {
        upload.state = UploadState.create(
          UploadStateCode.FAILED,
          upload.state.percent,
          upload.name + " failed. " + xhr.statusText
        );
        this.onUploadFailed(upload);
        upload.callback.trigger(new UploadResult(upload, false));
        if (upload.removeOnFailure) {
          this.remove(upload);
        }
      },
      false
    );

    // Open request and set any assigned headers
    xhr.open(nextUpload.method, nextUpload.url, true);
    nextUpload.headers.forEach((header) => {
      xhr.setRequestHeader(header.name, header.value);
    });

    // Increment active uploads
    this.activeUploads++;

    // Set upload state to uploading
    nextUpload.state = UploadState.create(UploadStateCode.UPLOADING, 0, nextUpload.name + " starting.");

    // Call start event
    this.onUploadStarted(nextUpload);

    // Upload file or data
    let blob;
    if (nextUpload.file) {
      // If file upload
      if (nextUpload.type) {
        xhr.setRequestHeader("content-type", nextUpload.type);
      }
      xhr.send(nextUpload.file);
    } else if (nextUpload.data) {
      // Data (blob) upload
      if (nextUpload.type) {
        blob = new Blob([nextUpload.data], { type: nextUpload.type });
        xhr.setRequestHeader("content-type", nextUpload.type);
      } else {
        blob = new Blob([nextUpload.data], { type: "" });
      }
      xhr.send(blob);
    }
  };
}

export class UploadHeader {
  name: string;
  value: string;

  constructor(name: string, value: string) {
    this.name = name;
    this.value = value;
  }
}

export enum UploadStateCode {
  NEW = 0,
  QUEUED = 1,
  UPLOADING = 2,
  FAILED = 3,
  ABORTED = 4,
  COMPLETED = 5,
}

export class UploadState {
  constructor(public code: UploadStateCode, public percent: number, public message: string) {}

  static create(code: UploadStateCode, percent: number, message: string): UploadState {
    return new UploadState(code, percent, message);
  }
}

export class Event<T> {
  private handlers: ((u: T) => void)[] = [];

  public add(handler: (u: T) => void): void {
    this.handlers.push(handler);
  }

  public remove(handler: (u: T) => void): void {
    const index = this.handlers.indexOf(handler);
    if (index > -1) {
      this.handlers.splice(index, 1);
    }
  }

  public hasListeners(): boolean {
    return this.handlers.length > 0;
  }

  public clear(): void {
    this.handlers.splice(0, this.handlers.length);
  }

  public trigger(u: T): void {
    this.handlers.slice(0).forEach((h) => h(u));
  }
}

export class UploadResult {
  constructor(public upload: Upload, public success: boolean) {}
}

export class Upload {
  name: string;
  type: string;
  size: number;
  url: string;
  containerID?: string;
  folderPath?: string;
  callback: Event<UploadResult> = new Event();

  id?: number; // Assigned by controller
  isImmediate = false;

  state: UploadState = new UploadState(UploadStateCode.NEW, 0, "New");
  method: string;
  headers: UploadHeader[];
  file?: File;
  data?: BlobPart;
  request?: XMLHttpRequest;

  removeOnSuccess: boolean;
  removeOnFailure: boolean;

  constructor(name: string, type: string, size: number, url: string) {
    this.name = name;
    this.type = type;
    this.size = size;
    this.url = url;

    this.method = "PUT";
    this.headers = [];
    this.removeOnSuccess = false;
    this.removeOnFailure = false;
  }

  static createFileUpload(name: string, type: string, size: number, url: string, file: File): Upload {
    const upload = new Upload(name, type, size, url);
    upload.file = file;
    return upload;
  }

  static createDataUpload(name: string, type: string, size: number, url: string, data: BlobPart): Upload {
    const upload = new Upload(name, type, size, url);
    upload.data = data;
    return upload;
  }

  addHeader(name: string, value: string): void {
    this.headers.push(new UploadHeader(name, value));
  }

  wasSuccessful(): boolean {
    if (this.state.code === UploadStateCode.COMPLETED) {
      return true;
    }
    return false;
  }

  lastStatus(): string {
    return this.state.message;
  }

  statusName(): string {
    switch (this.state.code) {
      case UploadStateCode.NEW:
        return "New";
      case UploadStateCode.QUEUED:
        return "Queued";
      case UploadStateCode.UPLOADING:
        return "Uploading";
      case UploadStateCode.ABORTED:
        return "Cancelled";
      case UploadStateCode.COMPLETED:
        return "Completed";
      case UploadStateCode.FAILED:
        return "Failed";
    }
  }
}

export const uploader = new UploadService();
