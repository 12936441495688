/* eslint-disable @typescript-eslint/no-inferrable-types */
import config from "../config/config.json";
import axios, { AxiosError } from "axios";
import { logger } from "@/services/utilities";

import ContainerDTO from "@/models/container-dto";
import ContainerFolderDTO from "@/models/container-folder-dto";
import LoginDTO from "@/models/login-dto";
import LoginRequest from "@/models/login-request";
import RegistrationInfo from "@/models/registration-info";
import ManifestDTO from "@/models/manifest-dto";
import SignedUrlRequestDTO from "@/models/signed-url-request-dto";
import ChangePasswordRequest from "@/models/change-password-request";

export default class SchematrixApi {
  static login(request: LoginRequest): Promise<LoginDTO> {
    return new Promise((resolve, reject) => {
      if (request.Name.trim().length === 0 || request.Password.trim().length === 0) {
        reject("Invalid login request");
      } else {
        axios
          .post<LoginDTO>(config.schematrixServiceUrl + "authenticate", request)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error: AxiosError) => {
            logger("API Error: " + error);
            if (error.response?.status === 401) {
              reject("Authentication Failed");
            } else {
              logger("API Error: " + JSON.stringify(error.response, null, 4));
              reject(error.response?.data);
            }
          });
      }
    });
  }

  static validateToken(token: string): Promise<LoginDTO> {
    return new Promise((resolve, reject) => {
      axios
        .get<LoginDTO>(config.schematrixServiceUrl + "authenticated", {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error: AxiosError) => {
          // If error is 401, assume expired token, else set login error
          if (error.response?.status === 401) {
            reject("Authorization Failed");
          } else {
            logger("API Error: " + JSON.stringify(error.response, null, 4));
            reject(error.response?.data);
          }
        });
    });
  }

  static refreshToken(token: string): Promise<LoginDTO> {
    return new Promise((resolve, reject) => {
      axios
        .get<LoginDTO>(config.schematrixServiceUrl + "refreshtoken", {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error: AxiosError) => {
          // If error is 401, assume expired token, else set login error
          if (error.response?.status === 401) {
            reject("Authorization Failed");
          } else {
            logger("API Error: " + JSON.stringify(error.response, null, 4));
            reject(error.response?.data);
          }
        });
    });
  }

  static checkNameUsage(name: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (name.trim().length === 0) {
        resolve(false);
      } else {
        axios
          .get<RegistrationInfo>(config.schematrixServiceUrl + "checknameavailability/" + encodeURIComponent(name.trim()))
          .then((info) => {
            if (info.data.LoginCount > 0 || info.data.RegistrationCount > 0) {
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .catch((error) => {
            logger("API Error: " + JSON.stringify(error.response, null, 4));
            reject(error.response?.data);
          });
      }
    });
  }

  static checkEmailUsage(email: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (email.trim().length === 0) {
        resolve(false);
      } else {
        axios
          .get<RegistrationInfo>(
            config.schematrixServiceUrl + "checkemailavailability/" + encodeURIComponent(email.trim()) + "/"
          )
          .then((info) => {
            if (info.data.LoginCount > 0 || info.data.RegistrationCount > 0) {
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .catch((error) => {
            logger("API Error: " + JSON.stringify(error.response, null, 4));
            reject(error.response?.data);
          });
      }
    });
  }

  static register(loginDTO: LoginDTO): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .post<LoginDTO>(config.schematrixServiceUrl + "register", loginDTO)
        .then(() => {
          resolve();
        })
        .catch((error: AxiosError) => {
          logger("API Error: " + JSON.stringify(error.response, null, 4));
          reject(error.response?.data);
        });
    });
  }

  static confirmRegistration(loginDTO: LoginDTO): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .post<LoginDTO>(config.schematrixServiceUrl + "confirmregistration", loginDTO)
        .then(() => {
          resolve();
        })
        .catch((error: AxiosError) => {
          logger("API Error: " + JSON.stringify(error.response, null, 4));
          reject(error.response?.data);
        });
    });
  }

  static requestPasswordReset(loginDTO: LoginDTO): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .post<LoginDTO>(config.schematrixServiceUrl + "sendpasswordresetcode", loginDTO)
        .then(() => {
          resolve();
        })
        .catch((error: AxiosError) => {
          logger(`API Error: ${error.response?.data}`);
          reject(error.response?.data);
        });
    });
  }

  static resetPassword(loginDTO: LoginDTO): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .post<LoginDTO>(config.schematrixServiceUrl + "resetpassword", loginDTO)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          logger("API Error: " + JSON.stringify(error.response, null, 4));
          reject(error.response?.data);
        });
    });
  }

  static changePassword(token: string, request: ChangePasswordRequest): Promise<void> {
    return new Promise((resolve, reject) => {
      if (
        request.Password === undefined ||
        request.Password.trim().length === 0 ||
        request.NewPassword === undefined ||
        request.NewPassword.trim().length === 0
      ) {
        reject("Invalid change password request");
      } else {
        axios
          .post(config.schematrixServiceUrl + "changepassword", request, {
            headers: {
              Authorization: "Bearer " + token,
            },
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error: AxiosError) => {
            logger("API Error: " + error);
            if (error.response?.status === 401) {
              reject("Authentication Failed");
            } else {
              logger("API Error: " + JSON.stringify(error.response, null, 4));
              reject(error.response?.data);
            }
          });
      }
    });
  }

  static updateUser(token: string, request: LoginDTO): Promise<void> {
    return new Promise((resolve, reject) => {
      if (
        request.Email === undefined ||
        request.Email.trim().length === 0 ||
        request.Alias === undefined ||
        request.Alias.trim().length === 0
      ) {
        reject("Invalid user update request.");
      } else {
        axios
          .put(config.schematrixServiceUrl + "user", request, {
            headers: {
              Authorization: "Bearer " + token,
            },
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error: AxiosError) => {
            logger("API Error: " + error);
            if (error.response?.status === 401) {
              reject("Authentication Failed");
            } else {
              logger("API Error: " + JSON.stringify(error.response, null, 4));
              reject(error.response?.data);
            }
          });
      }
    });
  }

  static resendRegistrationCode(loginDTO: LoginDTO): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .post<LoginDTO>(config.schematrixServiceUrl + "resendregistrationmessage", loginDTO)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          logger("API Error: " + JSON.stringify(error.response, null, 4));
          reject(error.response?.data);
        });
    });
  }

  static listContainers(token: string): Promise<ContainerDTO[]> {
    return new Promise((resolve, reject) => {
      axios
        .get<ContainerDTO[]>(config.schematrixServiceUrl + "container", {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error: AxiosError) => {
          if (error.response?.status === 401) {
            reject("Authorization Failed");
          } else {
            logger("API Error: " + JSON.stringify(error.response, null, 4));
            reject(error.response?.data);
          }
        });
    });
  }

  static createContainer(token: string, container: ContainerDTO): Promise<ContainerDTO> {
    return new Promise((resolve, reject) => {
      axios
        .post<ContainerDTO>(config.schematrixServiceUrl + "container", JSON.stringify(container), {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error: AxiosError) => {
          if (error.response?.status === 401) {
            reject("Authorization Failed");
          } else {
            logger("API Error: " + JSON.stringify(error.response, null, 4));
            reject(error.response?.data);
          }
        });
    });
  }

  static updateContainer(token: string, container: ContainerDTO): Promise<ContainerDTO> {
    return new Promise((resolve, reject) => {
      axios
        .put<ContainerDTO>(config.schematrixServiceUrl + "container", JSON.stringify(container), {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error: AxiosError) => {
          if (error.response?.status === 401) {
            reject("Authorization Failed");
          } else {
            logger("API Error: " + JSON.stringify(error.response, null, 4));
            reject(error.response?.data);
          }
        });
    });
  }

  static deleteContainer(token: string, containerID: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .delete(config.schematrixServiceUrl + "container/" + encodeURIComponent(containerID), {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        })
        .then(() => {
          resolve();
        })
        .catch((error: AxiosError) => {
          if (error.response?.status === 401) {
            reject("Authorization Failed");
          } else {
            logger("API Error: " + JSON.stringify(error.response, null, 4));
            reject(error.response?.data);
          }
        });
    });
  }

  static createFolder(token: string, containerFolder: ContainerFolderDTO): Promise<ContainerFolderDTO> {
    return new Promise((resolve, reject) => {
      axios
        .post<ContainerFolderDTO>(config.schematrixServiceUrl + "container/folder", JSON.stringify(containerFolder), {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error: AxiosError) => {
          if (error.response?.status === 401) {
            reject("Authorization Failed");
          } else {
            logger("API Error: " + JSON.stringify(error.response, null, 4));
            reject(error.response?.data);
          }
        });
    });
  }

  static deleteFolder(token: string, containerID: string, folderPath: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          config.schematrixServiceUrl +
            "container/folder/?ContainerID=" +
            encodeURIComponent(containerID) +
            "&path=" +
            encodeURIComponent(folderPath),
          {
            headers: {
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
          }
        )
        .then(() => {
          resolve();
        })
        .catch((error: AxiosError) => {
          if (error.response?.status === 401) {
            reject("Authorization Failed");
          } else {
            logger("API Error: " + JSON.stringify(error.response, null, 4));
            reject(error.response?.data);
          }
        });
    });
  }

  static getSignedUrl(token: string, urlRequest: SignedUrlRequestDTO): Promise<SignedUrlRequestDTO> {
    return new Promise((resolve, reject) => {
      axios
        .post<SignedUrlRequestDTO>(config.schematrixServiceUrl + "container/getsignedurl", JSON.stringify(urlRequest), {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error: AxiosError) => {
          // If error is 401, assume expired token, else set login error
          if (error.response?.status === 401) {
            reject("Authorization Failed");
          } else {
            logger("API Error: " + JSON.stringify(error.response, null, 4));
            reject(error.response?.data);
          }
        });
    });
  }

  static getContainerManifest(
    token: string,
    containerID: string,
    full: boolean = false,
    rootPath: string = "/",
    includeFiles: boolean = false,
    includeGetUrls: boolean = false,
    includePutUrls: boolean = false
  ): Promise<ManifestDTO> {
    return new Promise((resolve, reject) => {
      let url = config.schematrixServiceUrl + "container/manifest/" + containerID + "?";
      if (includeFiles) {
        url += "includeFiles=true";
      } else {
        url += "includeFiles=false";
      }
      if (full) {
        url += "&full=true";
      }
      if (rootPath) {
        url += "&rootPath=" + encodeURIComponent(rootPath);
      }
      if (includeGetUrls) {
        url += "&includeGetUrls=true";
      }
      if (includePutUrls) {
        url += "&includePutUrls=true";
      }
      axios
        .get<ManifestDTO>(url, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error: AxiosError) => {
          if (error.response?.status === 401) {
            reject("Authorization Failed");
          } else {
            logger("API Error: " + JSON.stringify(error.response, null, 4));
            reject(error.response?.data);
          }
        });
    });
  }
}
