
import ModalResult from "@/models/modal-result";
import { Options, Vue } from "vue-class-component";
@Options({
  props: {
    containerName: {
      type: String,
      default: "",
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    isProcessing: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["modal-closed"],
  methods: {
    onOk() {
      this.$emit("modal-closed", new ModalResult(false, this.containerName));
    },
    onCancel() {
      this.$emit("modal-closed", new ModalResult(true, this.containerName));
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.modal.focus();
    });
  },
})
export default class DeleteContainerModal extends Vue {}
