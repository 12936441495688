<template>
  <div
    v-bind:class="{ 'is-active': isActive }"
    class="modal"
    tabindex="0"
    style="user-select: none"
    ref="modal"
    v-on:keyup.esc="onCancel"
  >
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Confirm File Delete</p>
        <button class="delete" aria-label="close" @click="onCancel"></button>
      </header>
      <section class="modal-card-body">Confirm delete of file {{ filename }}?</section>
      <footer class="modal-card-foot">
        <button class="button is-danger" @click="onOk">Yes, Delete</button>
        <button class="button" @click="onCancel">Cancel</button>
      </footer>
    </div>
  </div>
</template>

<script lang="ts">
import ModalResult from "@/models/modal-result";
import { Options, Vue } from "vue-class-component";
@Options({
  props: {
    filename: {
      type: String,
      default: "",
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["modal-closed"],
  methods: {
    onOk() {
      this.$emit("modal-closed", new ModalResult(false, this.filename));
    },
    onCancel() {
      this.$emit("modal-closed", new ModalResult(true, this.filename));
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.modal.focus();
    });
  },
})
export default class DeleteFileModal extends Vue {}
</script>
