<template>
  // eslint-disable-next-line prettier/prettier
  <div v-bind:class="{ 'is-active': isActive }" class="modal" tabindex="0" style="user-select: none" v-on:keyup.esc="onCancel">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Change Password</p>
        <button class="delete" aria-label="close" @click="onCancel"></button>
      </header>
      <section class="modal-card-body">
        <form>
          <div class="field">
            <div class="control">
              <input class="input" type="text" autocomplete="username" v-model="username" style="display: none" />
            </div>
          </div>
          <div class="field">
            <label class="label has-text-left">Current Password</label>
            <div class="control">
              <input
                ref="currentPassword"
                class="input"
                type="password"
                autocomplete="current-password"
                v-model="currentPassword"
                :disabled="isProcessing"
                @input="currentPasswordInput = true"
              />
            </div>
            <div style="color: red" class="has-text-left" v-if="!currentPasswordOk && currentPasswordInput">
              {{ currentPasswordErrors }}
            </div>
          </div>
          <div class="field">
            <label class="label has-text-left">New Password</label>
            <div class="control">
              <input
                class="input"
                type="password"
                autocomplete="new-password"
                v-model="newPassword"
                :disabled="isProcessing"
                @input="newPasswordInput = true"
              />
            </div>
            <div style="color: red" class="has-text-left" v-if="!newPasswordOk && newPasswordInput">
              {{ newPasswordErrors }}
            </div>
          </div>
          <div class="field">
            <label class="label has-text-left">Confirm New Password</label>
            <div class="control">
              <input
                class="input"
                type="password"
                autocomplete="new-password"
                v-model="newPasswordConfirm"
                :disabled="isProcessing"
                @input="newPasswordConfirmInput = true"
              />
            </div>
            <div style="color: red" class="has-text-left" v-if="!newPasswordConfirmOk && newPasswordConfirmInput">
              {{ newPasswordConfirmErrors }}
            </div>
          </div>
        </form>
      </section>
      <footer class="modal-card-foot">
        <button
          class="button is-danger"
          @click="onOk"
          :disabled="!formOk || isProcessing"
          v-bind:class="{ 'is-loading': isProcessing }"
        >
          Save
        </button>
        <button class="button" @click="onCancel">Cancel</button>
      </footer>
    </div>
  </div>
</template>

<script lang="ts">
import ChangePasswordRequest from "@/models/change-password-request";
import ModalResult from "@/models/modal-result";
import { Options, Vue } from "vue-class-component";
@Options({
  data() {
    return {
      username: "",
      currentPassword: "",
      currentPasswordErrors: "",
      currentPasswordInput: false,
      newPassword: "",
      newPasswordErrors: "",
      newPasswordInput: false,
      newPasswordConfirm: "",
      newPasswordConfirmErrors: "",
      newPasswordConfirmInput: false,
    };
  },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    isProcessing: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currentPasswordOk() {
      return this.currentPassword.trim().length > 0;
    },
    newPasswordOk() {
      return this.newPassword.trim().length > 0;
    },
    newPasswordConfirmOk() {
      return this.newPasswordConfirm.trim().length > 0 && this.newPasswordConfirm === this.newPassword;
    },
    formOk() {
      return this.validateForm();
    },
  },
  emits: ["modal-closed"],
  methods: {
    validateForm() {
      this.currentPasswordErrors = "";
      this.newPasswordErrors = "";
      this.newPasswordConfirmErrors = "";
      if (!this.currentPassword) this.currentPasswordErrors = "Current password is required.";
      if (!this.newPassword) this.newPasswordErrors = "New password is required.";
      if (!this.newPasswordConfirm) this.newPasswordConfirmErrors = "New password confirm is required.";
      if (this.newPassword !== this.newPasswordConfirm)
        this.newPasswordConfirmErrors = "New Password and new password confirm don't match.";
      return (
        this.currentPasswordInput &&
        this.newPasswordInput &&
        this.newPasswordConfirmInput &&
        !(this.currentPasswordErrors || this.newPasswordErrors || this.newPasswordConfirmErrors)
      );
    },
    onOk() {
      let request = new ChangePasswordRequest();
      request.Password = this.currentPassword;
      request.NewPassword = this.newPassword;
      this.$emit("modal-closed", new ModalResult(false, request));
    },
    onCancel() {
      this.$emit("modal-closed", new ModalResult(true, undefined));
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.currentPassword.focus();
    });
  },
})
export default class ChangePasswordModal extends Vue {}
</script>
