
import { Options, Vue } from "vue-class-component";
import ModalResult from "@/models/modal-result";
@Options({
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    link: {
      type: String,
      default: "",
    },
    filename: {
      type: String,
      default: "",
    },
  },
  emits: ["modal-closed"],
  methods: {
    onCancel() {
      this.$emit("modal-closed", new ModalResult<string>(true, ""));
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.link.focus();
      this.$refs.link.select();
    });
  },
})
export default class FileLinkModal extends Vue {}
